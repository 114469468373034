// 全国地级市经纬度
const provinceName = [
    //23个省、5个自治区、4个直辖市、2个特别行政区
    //5个自治区: 新疆维吾尔自治区 内蒙古自治区 西藏自治区 宁夏回族自治区 广西壮族自治区
    {
        name: "Xinjiang Uygur Autonomous Region",

    },
    {
        name: "Inner Mongolia Autonomous Region",

    },
    {
        name: "Xizang Autonomous Region",

    },
    {
        name: "Ningxia Hui Autonomous Region",

    },
    {
        name: "Guangxi Zhuang Autonomous Region",

    },
    // 两个特别行政区
    {
        name: "Macao Special Administrative Region",

    },
    {
        name: "Hong Kong Special Administrative Region",

    },
    // 四个直辖市
    {
        name: "Beijing",

    },
    {
        name: "Shanghai",

    },
    {
        name: "Chongqing",

    },
    {
        name: "Tianjing",

    },
    // 23个省
    {
        name: "Gansu",

    },

    {
        name: "Qinghai",

    },
    {
        name: "Sichuan",

    },
    {
        name: "Yunnan",

    },

    {
        name: "Shaanxi",

    },
    {
        name: "Hebei",

    },
    {
        name: "Shanxi",

    },
    {
        name: "Henan",

    },

    {
        name: "Hubei",

    },
    {
        name: "Guizhou",

    },
    {
        name: "Hunan",

    },
    {
        name: "Guangdong",

    },
    {
        name: "Hainan",

    },
    {
        name: "Heilongjiang",

    },
    {
        name: "Jilin",

    },
    {
        name: "Liaoning",

    },

    {
        name: "Shandong",

    },
    {
        name: "Anhui",

    },
    {
        name: "Jiangsu",

    },

    {
        name: "Jiangxi",

    },
    {
        name: "Zhejiang",

    },
    {
        name: "Fujian",

    },
    {
        name: "Taiwan",

    },
]
export default provinceName