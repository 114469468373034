// 全国地级市经纬度
const provinceName = [
    //23个省、5个自治区、4个直辖市、2个特别行政区
    //5个自治区: 新疆维吾尔自治区 内蒙古自治区 西藏自治区 宁夏回族自治区 广西壮族自治区
    {
        name: "新疆维吾尔自治区",

    },
    {
        name: "内蒙古自治区",

    },
    {
        name: "西藏自治区",

    },
    {
        name: "宁夏回族自治区",

    },
    {
        name: "广西壮族自治区",

    },
    // 两个特别行政区
    {
        name: "澳门特别行政区",

    },
    {
        name: "香港特别行政区",

    },
    // 四个直辖市
    {
        name: "北京",

    },
    {
        name: "上海",

    },
    {
        name: "重庆",

    },
    {
        name: "天津",

    },
    // 23个省
    {
        name: "甘肃",

    },

    {
        name: "青海",

    },
    {
        name: "四川",

    },
    {
        name: "云南",

    },

    {
        name: "山西",

    },
    {
        name: "河北",

    },
    {
        name: "陕西",

    },
    {
        name: "河南",

    },

    {
        name: "湖北",

    },
    {
        name: "贵州",

    },
    {
        name: "湖南",

    },
    {
        name: "广东",

    },
    {
        name: "海南",

    },
    {
        name: "黑龙江",

    },
    {
        name: "吉林",

    },
    {
        name: "辽宁",

    },

    {
        name: "山东",

    },
    {
        name: "安徽",

    },
    {
        name: "江苏",

    },

    {
        name: "江西",

    },
    {
        name: "浙江",

    },
    {
        name: "福建",

    },
    {
        name: "台湾",

    },
]
export default provinceName