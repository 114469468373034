<!-- 图表组件，echarts、highcharts -->
<template>
  <div class="div_charts">
    <!-- echarts图表 -->
    <v-chart
      :theme="themeColor"
      @click="linkagegetData"
      v-if="!isHighcharts"
      autoresize
      :options="options"
      ref="myecharts"
      style="width: 100%; height: 100%"
    ></v-chart>
    <div
      ref="funnleChartsRef"
      v-if="isHighcharts"
      style="width: 100%; height: 100%"
    ></div>
  </div>
</template>

<script>
/**
 * @description: 图表
 * 功能：
 * 1、图表展示
 * 逻辑：
 * 1、根据传入数据,展示不同类型图表,
 * 以及echarts 条形图、柱状图、瀑布图、折线图、饼图、圆环图、漏斗图、指针图、柱状堆叠图、
 * 条形堆叠图、面积堆叠图、热力图、世界地图、美国地图、雷达图、双轴图、目标图、漏斗图、矩阵树形图、气泡图、同环比双轴图
 */
import chinaChinese from "@/config/heatMap/chinaChinese.json"; // 中国地图中文配置文件
import chinaEnglish from "@/config/heatMap/chinaEnglish.json"; // 中国地图英文配置文件
import provinceChinese from "@/config/heatMap/provinceChinese.js"; //中国省份中文配置文件
import provinceEnglish from "@/config/heatMap/provinceEnglish.js"; // 中国省份英文配置文件
import cityChinese from "@/config/heatMap/cityChinese.json"; //中国市级中文配置文件
import cityEnglish from "@/config/heatMap/cityEnglish.json"; // 中国市级英文配置文件
import { units, statisticsTypes } from "@/config/reportDataType/unitData";
import Highcharts from "highcharts";
require("highcharts/modules/funnel")(Highcharts);

export default {
  props: {
    // 组建编辑器弹框
    isShowComponentsEditorAlert: {
      type: Boolean,
      default: false,
    },
    listChartData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Array | Object,
    },
    // 判断图表是不是在组件编辑中实时动态显示数据
    dashboardUseChart: {
      type: String,
      default: "",
    },
    // 属于联动还是穿透状态，1：联动，2：穿透
    status: {
      type: String,
      default: "",
    },
    /**
     *  是否为编辑状态
     * 非编辑状态不可拖动，图表不可编辑
     */
    isEditStatus: {
      type: Boolean,
      default: false,
    },
    /**
     * 图表使用位置
     * report 报表、仪表板dashboard、chartFullScreen 图表全屏、dashboardFullScreen 仪表板全屏
     */
    useLocation: {
      type: String,
      default: "",
    },
    /**
     * 图表类型
     */
    dashboardtype: {
      type: String,
      default: "",
    },
    /**
     * 一级分组数据
     */
    groupOneArr: {
      type: Array,
      default: () => [],
    },
    /**
     * 统计数据
     */
    valueDataArr: {
      type: Array,
      default: () => [],
    },
    /**
     * 饼图、圆环的series数据
     * 地图热力图 data数据
     */
    dataArr: {
      type: Array,
      default: () => [],
    },
    /**
     * 有二级分组的series数据
     */
    seriesArr: {
      type: Array,
      default: () => [],
    },
    /**
     * dimensions数据,用于指定维度顺序
     */
    dimensionsArr: {
      type: Array,
      default: () => [],
    },
    /**
     * 图表数据源
     */
    sourceArr: {
      type: Array,
      default: () => [],
    },
    /**
     * 指针图的最小值
     */
    min: {
      type: String,
      default: "",
    },
    /**
     * 指针图的最大值
     */
    max: {
      type: String,
      default: "",
    },
    /**
     * 指针图的断点1值
     */
    mid1: {
      type: String,
      default: "",
    },
    /**
     * 指针图的断点2值
     */
    mid2: {
      type: String,
      default: "",
    },
    /**
     * 是否显示百分比
     */
    isshowpercent: {
      type: String,
      default: "false",
    },
    /**
     * 是否显示数值
     */
    isshowvalue: {
      type: String,
      default: "false",
    },
    /**
     * 一级分组字段label
     */
    mapGroupOneFieldLabelName: {
      type: String,
      default: "",
    },
    /**
     * 一级分组字段信息
     */
    mapGroupOneField: {
      type: Object,
      default: () => {},
    },
    /**
     * 统计字段label
     */
    mapSummaryFieldLabelName: {
      type: String,
      default: "",
    },
    /**
     * 统计类型
     */
    mapSummaryWay: {
      type: String,
      default: "",
    },
    /**
     * 列表视图数据源对象名称
     */
    objectLable: {
      type: String,
      default: "",
    },
    /**
     * 报表数据源报表名称
     */
    reportName: {
      type: String,
      default: "",
    },
    /**
     * 图表选择的单位
     */
    unit: {
      type: String,
      default: "",
    },
    /**
     * 图表数据设置
     */
    showData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mapMax: 0, // 最大值
      mapValueArr: [], // 热力图、地图位置信息集合
      options: {}, // 图表options数据
      HighchartsCurrentClickName: "", // 漏斗图表的点击事件获取到的当前点击的name
      mapTypes: "map", //显示省份还是市
      listGroupData: [], //双轴图数据
      BiaxialFigureCheck: "", //双轴图是否统一刻度
      setChangeBarData:'',//柱状图是否显示数据
      chooseCurrentIndex: "", //联动图表的下标
      chooseCurrentType: "", //联动的图表类型
      interceptStrData: [], //Y轴截取20个字节之后的数据
      maxyAxisNameGap: 0, //Y轴距离最大值
      chartFiledLength: 0, //图表每个柱子宽度,如果宽度大于20，就不使用间隔，小于20计算显示数量
      zoomMinSpan: "", //滚动条设置显示最小值数量
      pieWidth:100,//饼图宽度
    };
  },
  computed: {
    /**
     * 所有图表信息数据均需要监听，然后重新渲染，所以设置一个计算属性值
     */
    chartInfos() {
      return (
        this.dashboardtype,
        this.groupOneArr,
        this.listChartData,
        this.valueDataArr,
        this.dataArr,
        this.seriesArr,
        this.dimensionsArr,
        this.sourceArr,
        this.min,
        this.max,
        this.mid1,
        this.mid2,
        this.isshowpercent,
        this.isshowvalue,
        this.mapGroupOneFieldLabelName,
        this.mapSummaryFieldLabelName,
        this.mapSummaryWay,
        this.objectLable,
        this.reportName,
        this.unit
      );
    },
    /**
     * 设置主题颜色
     */
    themeColor() {
      // 判断是否在全屏模式下，非全屏模式直接设置浅色即可
      if (this.$store.getters.getIsFullScreen) {
        return this.$store.getters.getThemeColor;
      }
      return "roma";
    },
    // 属于highcharts图表
    isHighcharts() {
      if (this.dashboardtype === "funnel") {
        return true;
      }
      return false;
    },
  },
  mounted() {
    // 柱形图是否显示数据
    this.$bus.$on("ChangeBarData",this.changeBarDataCallback);
    // 双轴图统计字段数据
    this.$bus.$on("changeBiaxialCheck", this.changeBiaxialCheckCallback);
    this.$bus.$on("windowResize", this.windowResizeCallback);
  },
  beforeDestroy() {
    // 柱形图是否显示数据
    this.$bus.$off("ChangeBarData",this.changeBarDataCallback);
    // 双轴图统计字段数据
    this.$bus.$off("changeBiaxialCheck", this.changeBiaxialCheckCallback);
    this.$bus.$off("windowResize", this.windowResizeCallback);
  },
  watch: {
    /**
     * 监听图表信息数据的改变，重新渲染图表
     */
    chartInfos: {
      handler() {
        this.drawChart();
      },
      deep: true,
      immediate: true,
    },
    // 监听图表联动，修改图表颜色
    chooseCurrentIndex: {
      handler() {
        this.drawChart();
      },
    },
    /**
     * 监听主题颜色，当改变时需重新渲染图表
     */
    themeColor: {
      handler() {
        this.drawChart();
      },
    },
    BiaxialFigureCheck: {
      handler() {
        this.getOptionsBiaxialFigure("dynamic");
      },
      deep: true,
    },
    // 柱状图是否显示数据
    // 是：根据当前图表类型，对柱状图和条形图进行设置
    // 否：不执行设置
    setChangeBarData: {
      handler() {
        if(this.dashboardtype=='bar_0'){
          this.getOptionsBar_0();
          this.setBarChartsYxias(
            this.options,
            this.ischangeYesOrNo(this.groupOneArr),
            "y"
          );
          if(this.setChangeBarData){
            this.setBarTypeChart(this.options)
          }
        }else if(this.dashboardtype=='column_0'){
          this.getOptionsColumn_0();
          this.changeAxisName(false, this.options);
          if(this.setChangeBarData){
            this.setColumnTypeChart(this.options)  
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    windowResizeCallback () {
      if (this.$refs.myecharts && this.$refs.myecharts.$el) {
        this.pieWidth = parseInt(
          (this.$refs.myecharts.$el.clientWidth * 0.8) / 3
        );
      } else {
        this.pieWidth = 100;
      }
      // 饼图和环形图在英文环境单词不拆分，计算width
      if ((this.dashboardtype == "pie" || this.dashboardtype == "donut" )&&Number(this.pieWidth)>5) {
        this.drawChart();
      }
    },
    changeBiaxialCheckCallback(val){
      this.BiaxialFigureCheck = val;
    },
   
    changeBarDataCallback (val){
      this.setChangeBarData = val;
    },
    // 设置水平柱状图显示数据
    setColumnTypeChart(data){
      if(data.series &&data.series.length>0){
        data.series.map((item)=>{
          item.label={
            show: true,
            position: "top"
          }
        })
      }
    },
    // 设置垂直柱状图显示数据
    setBarTypeChart(data){
      if(data.series &&data.series.length>0){
        data.series.map((item)=>{
          item.label={
            show: true,
            position: "right"
          }
        })
      }
    },
    // 地图中只有数据大于0的才在地图中显示
    showMapData(data) {
      let newArray = [];
      data.map((item) => {
        // 城市：value中前两位是经纬度，第三位是数据
        // 省：value就是数据
        if ((item.value && item.value.length==3 && item.value[2] > 0)||item.value>0) {
          newArray.push(item);
        }
      });
      return newArray;
    },
    // 处理复选框字段数据,true和false转换成是或否
    ischangeYesOrNo(data) {
      let newData = [];
      if (
        this.mapGroupOneField &&
        this.mapGroupOneField.schemefieldType == "B"
      ) {
        data.map((item) => {
          // 只有一个数据且这个数据为否时，或者没有数据
          if (
            (newData.length == 1 &&
              (newData[0] == this.$i18n.t("label.tabpage.noz") ||
                newData[0].name == this.$i18n.t("label.tabpage.noz") ||
                newData[0].product == this.$i18n.t("label.tabpage.noz"))) ||
            newData.length == 0 ||
            (newData[0][0] == this.$i18n.t("label.tabpage.noz") &&
              this.dashboardtype == "funnel" &&
              newData[0].length == 2 &&
              newData.length == 1)
          ) {
            if (item == "true" || item == "1"|| item == this.$i18n.t("label.tabpage.yesz")) {
              newData.push(this.$i18n.t("label.tabpage.yesz"));
            } else if (
              this.dashboardtype == "column_1" &&
              (item.product == "true" || item.product == "1"|| item.product == this.$i18n.t("label.tabpage.yesz"))
            ) {
              // 二维柱状图
              item.product = this.$i18n.t("label.tabpage.yesz");
              newData.push(item);
            } else if (
              this.dashboardtype == "funnel" &&
              (item[0] == "true" || item[0] == "1"|| item[0] == this.$i18n.t("label.tabpage.yesz"))
            ) {
              // 漏斗图
              item[0] = this.$i18n.t("label.tabpage.yesz");
              newData.push(item);
            } else if (
              (this.dashboardtype == "pie" || this.dashboardtype == "donut") &&
              (item.name == "true" || item.name == "1"|| item.name == this.$i18n.t("label.tabpage.yesz"))
            ) {
              // 圆环图和饼图
              item.name = this.$i18n.t("label.tabpage.yesz");
              newData.push(item);
            }
          } else if (
            (newData.length == 1 &&
              (newData[0] == this.$i18n.t("label.tabpage.yesz") ||
                newData[0].name == this.$i18n.t("label.tabpage.yesz") ||
                newData[0].product == this.$i18n.t("label.tabpage.yesz"))) ||
            newData.length == 0 ||
            (newData[0][0] == this.$i18n.t("label.tabpage.yesz") &&
              this.dashboardtype == "funnel" &&
              newData[0].length == 2 &&
              newData.length == 1)
          ) {
            // 只有一个数据且这个数据为是时，或者没有数据
            if (item == "false" || item == "0"|| item == this.$i18n.t("label.tabpage.noz")) {
              newData.push(this.$i18n.t("label.tabpage.noz"));
            } else if (
              this.dashboardtype == "column_1" &&
              (item.product == "false" || item.product == "0"|| item.product == this.$i18n.t("label.tabpage.noz"))
            ) {
              // 漏斗图
              item.product = this.$i18n.t("label.tabpage.noz");
              newData.push(item);
            } else if (
              this.dashboardtype == "funnel" &&
              (item[0] == "false" || item[0] == "0"|| item[0] == this.$i18n.t("label.tabpage.noz"))
            ) {
              // 圆环图和饼图
              item[0] = this.$i18n.t("label.tabpage.noz");
              newData.push(item);
            } else if (
              (this.dashboardtype == "pie" || this.dashboardtype == "donut") &&
              (item.name == "false" || item.name == "0"|| item.name == this.$i18n.t("label.tabpage.noz"))
            ) {
              item.name = this.$i18n.t("label.tabpage.noz");
              newData.push(item);
            }
          }
        });
        return newData;
      } else {
        return data;
      }
    },
    // 复选框对应的数据也应该处理
    // data:坐标轴名称
    // value：坐标轴对应的数据
    ismodifyData(data, list) {
      let newList = [];
      if (
        this.mapGroupOneField &&
        this.mapGroupOneField.schemefieldType == "B"
      ) {
        data.map((item, index) => {
          if (
            (item == "true" ||
              item[0] == "true" ||
              item == "1" ||
              item[0] == "1") &&
            newList.length < 3
          ) {
            newList.push(list[index]);
          } else if (
            (item == "false" ||
              item[0] == "false" ||
              item == "0" ||
              item[0] == "0") &&
            newList.length < 3
          ) {
            newList.push(list[index]);
          }
        });
        return newList;
      } else {
        return list;
      }
    },
    // 饼图环形图，漏斗图显示单位
    pieChartsUnit() {
      let company = "";
      if (this.unit == "ten thousand") {
        //万
        return (company =
          " " + this.$i18n.t("label.dashboardSetup.showdw.d4") + " ");
      } else if (this.unit == "million") {
        //百万
        return (company =
          " " + this.$i18n.t("label.dashboardSetup.showdw.d3") + " ");
      } else if (this.unit == "ten million") {
        //千万
        return (company =
          " " + this.$i18n.t("label.dashboardSetup.showdw.d2") + " ");
      } else if (this.unit == "billion") {
        //亿
        return (company =
          " " + this.$i18n.t("label.dashboardSetup.showdw.d1") + " ");
      } else {
        // eslint-disable-next-line no-unused-vars
        return (company = "");
      }
    },
    // 1、X，Y轴根据截取20个字节
    // 2、截取之后计算每个标签的长度，取最大值
    interceptStr(str) {
      let L = 19; //截取20个字节
      if (this.useLocation === "chartFullScreen") {
        L = 29;
      } else {
        L = 19;
      }
      var result = "",
        strlen = str.length, // 字符串长度
        // eslint-disable-next-line no-control-regex
        chrlen = str.replace(/[^\x00-\xff]/g, "**").length; // 字节长度
      if (chrlen <= L) {
        return str;
      }
      for (var i = 0, j = 0; i < strlen; i++) {
        var chr = str.charAt(i);
        // eslint-disable-next-line no-control-regex
        if (/[\x00-\xff]/.test(chr)) {
          j++; // ascii码为0-255，一个字符就是一个字节的长度
        } else {
          j += 2; // ascii码为0-255以外，一个字符就是两个字节的长度
        }
        if (j <= L) {
          // 当加上当前字符以后，如果总字节长度小于等于L，则将当前字符真实的+在result后
          result += chr;
        } else {
          // 反之则说明result已经是不拆分字符的情况下最接近L的值了，直接返回
          return result + "..";
        }
      }
    },
    // 根据图表的宽度，数据量计算图表显示方式：
    // 1、X轴是否需要倾斜显示
    // 2、X轴是否需要显示滚动条
    // Yxiasoptions：this.options,
    // labelData:X或Y轴标签名数据
    // Axis：X轴或Y轴
    setBarChartsYxias(Yxiasoptions, labelData, Axis) {
      // 不同情况下获取dom元素的宽度
      let getMyechartWidth = 0;
      if (this.$refs.myecharts) {
        getMyechartWidth = this.$refs.myecharts.$el.clientWidth;
      } else if (this.useLocation === "chartFullScreen") {
        getMyechartWidth = parseInt(parseInt(window.innerWidth * 0.9) - 172);
      } else {
        // eslint-disable-next-line no-unused-vars
        getMyechartWidth = 480;
      }
      // 不同情况下获取dom元素的高度
      let getMyechartHeight = 0;
      if (this.$refs.myecharts) {
        getMyechartHeight = this.$refs.myecharts.$el.clientHeight;
      } else if (this.useLocation === "chartFullScreen") {
        getMyechartHeight = parseInt(parseInt(window.innerHeight * 0.8) * 0.7);
      } else if (this.dashboardUseChart == "realdynamic") {
        getMyechartHeight = 300;
      } else if (this.isShowComponentsEditorAlert) {
        // eslint-disable-next-line no-unused-vars
        getMyechartHeight = 300;
      }
      this.maxyAxisNameGap = 0;
      this.interceptStrData = [];
      this.calculationMinWidth(labelData); //计算图表中每个图形的最小宽度
      // 截取每个标签名20个字符，并计算字符中的maxwidth
      labelData.map((item) => {
        if (item) {
          this.interceptStrData.push(this.interceptStr(item));
        }
      });
      // 20个字节中最大长度
      this.interceptStrData.map((item) => {
        if (
          this.maxyAxisNameGap < this.getTextWidth(item, "normal 12px Robot")
        ) {
          this.maxyAxisNameGap = this.getTextWidth(item, "normal 12px Robot");
        }
      });
      // 面积堆叠图,双轴图，同环比双轴图和其他类型图表取值不同
      let optionsxAxis = Yxiasoptions.xAxis;
      if (
        this.dashboardtype == "area" ||
        this.dashboardtype == "biaxialFigure" ||
        this.dashboardtype == "biaxial"
      ) {
        optionsxAxis = Yxiasoptions.xAxis[0];
      } else {
        optionsxAxis = Yxiasoptions.xAxis;
      }
      let isRotate = 0; //是否需要倾斜显示
      // 图表中单个柱子的宽度-5，大于标签名的长度不需要倾斜显示
      if (this.chartFiledLength - 5 > this.maxyAxisNameGap) {
        isRotate = 0;
      } else {
        isRotate = 30;
      }
      this.setChartZoom(labelData); //显示滚动条时内容区域显示百分之几的数据
      // 全屏情况下标签宽度增加
      if (Axis == "y") {
        this.maxyAxisNameGap = this.maxyAxisNameGap + 15;
      } else if (Axis == "x") {
        this.maxyAxisNameGap = this.maxyAxisNameGap + 35;
      }
      if (Axis == "x") {
        // 设置X轴的namegap
        if (isRotate == 0) {
          optionsxAxis.nameGap = 30;
        } else {
          optionsxAxis.nameGap = parseInt(this.maxyAxisNameGap / 2);
        }
        // 二维柱形图一条数据包含多个柱子，设置最小宽度为8，并且显示的百分比在返回值的基础上*0.5
        let maxSpan = "";
        if (
          this.dashboardtype == "column_1" &&
          this.useLocation != "chartFullScreen" &&
          this.sourceArr &&
          this.sourceArr.length * (Object.keys(this.sourceArr[0]).length - 1) >
            20
        ) {
          maxSpan = this.zoomMinSpan * 0.5;
        } else {
          maxSpan = this.zoomMinSpan;
        }
        // 当图形minWidth<12时，设置图形最小宽度，并显示滚动条
        if (
          (this.chartFiledLength < 12 || this.dashboardtype == "column_1") &&
          this.zoomMinSpan != 100
        ) {
          optionsxAxis.axisLabel = {
            interval: 0, //计算间隔几个标签
            rotate: isRotate, //计算是否需要倾斜显示：倾斜：30，不倾斜：0
            formatter: (value) => this.interceptStr(value), //截取字符串
            fontSize: 12,
          };
          optionsxAxis.axisTick = {
            show: false, //去除X轴上的竖线
          };
          //二维柱形图一个格子代表中包含多个柱子，只显示一个标签名，最下宽度要设置的小一点
          Yxiasoptions.barMinWidth = this.dashboardtype == "column_1" ? 8 : 12;
          Yxiasoptions.barCategoryGap = "80%";
          Yxiasoptions.dataZoom = {
            type: "slider",
            height: 12,
            start: 0,
            bottom: "0",
            moveHandleSize: 0, //移动手柄的尺寸高度。
            left: "10%",
            width: "80%",
            zoomLock: true, //如果设置为 true 则锁定选择区域的大小，也就是说，只能平移，不能缩放。
            showDetail: false, //是否显示detail，即拖拽时候显示详细数值信息。
            maxSpan: maxSpan,
            showDataShadow: false, //是否在 dataZoom-silder 组件中显示数据阴影。数据阴影可以简单地反应数据走势。
          };
        } else if (this.chartFiledLength >= 25) {
          // 图形width>25时，每个标签都显示出来
          optionsxAxis.axisLabel = {
            interval: 0, //计算间隔几个标签
            rotate: isRotate, //计算是否需要倾斜显示：倾斜：30，不倾斜：0
            formatter: (value) => this.interceptStr(value), //截取字符串
          };
          optionsxAxis.axisTick = {
            show: false, //去除X轴上的竖线
          };
        } else {
          // width>12 && width<25时，设置标签计算相隔显示
          optionsxAxis.axisLabel = {
            interval: this.chartColumnXinterval(labelData), //计算间隔几个标签
            rotate: isRotate, //计算是否需要倾斜显示：倾斜：30，不倾斜：0
            formatter: (value) => this.interceptStr(value), //截取字符串
          };
          optionsxAxis.axisTick = {
            show: false, //去除X轴上的竖线
          };
        }
        // 如果图表中第一个标签名长度超过10，就设置left:85?60
        // 双轴图和同环比双轴图的right要单独设置
        if (
          this.interceptStrData[0] &&
          this.interceptStrData[0].length >= 10 &&
          this.useLocation != "chartFullScreen"
        ) {
          Yxiasoptions.grid.left = "95";
        } else {
          Yxiasoptions.grid.left = "60";
        }
        if (
          this.dashboardtype == "biaxialFigure" ||
          this.dashboardtype == "biaxial"
        ) {
          Yxiasoptions.grid.right = "60";
        } else {
          Yxiasoptions.grid.right = "20";
        }
        // 判断是否有图例和滚动条
        if (
          Yxiasoptions.legend &&
          Yxiasoptions.legend.width &&
          Yxiasoptions.dataZoom &&
          Yxiasoptions.dataZoom.width
        ) {
          // 双轴图和同环比双轴图在X轴没有标签名 高度-35
          if (
            this.dashboardtype == "biaxialFigure" ||
            this.dashboardtype == "biaxial"
          ) {
            if (isRotate == 30) {
              Yxiasoptions.grid.bottom =
                parseInt(Number(this.maxyAxisNameGap) / 2) + 40;
            } else {
              Yxiasoptions.grid.bottom = 50;
            }
          } else if (this.dashboardtype == "waterfall") {
            if (isRotate == 30) {
              Yxiasoptions.grid.bottom =
                parseInt(Number(this.maxyAxisNameGap) / 2) + 70;
            } else {
              Yxiasoptions.grid.bottom = 50;
            }
          } else {
            if (isRotate == 30) {
              Yxiasoptions.grid.bottom =
                parseInt(Number(this.maxyAxisNameGap) / 2) + 52;
            } else {
              Yxiasoptions.grid.bottom = 70;
            }
          }
          Yxiasoptions.legend.bottom = 15;
          Yxiasoptions.grid.containLabel = false;
        } else if (Yxiasoptions.dataZoom && Yxiasoptions.dataZoom.width) {
          // 判断是否有滚动条
          // 双轴图和同环比双轴图在X轴没有标签名
          if (
            this.dashboardtype == "biaxialFigure" ||
            this.dashboardtype == "biaxial"
          ) {
            if (isRotate == 30) {
              Yxiasoptions.grid.bottom =
                parseInt(Number(this.maxyAxisNameGap) / 2) + 30;
            } else {
              Yxiasoptions.grid.bottom = 50;
            }
          } else {
            if (isRotate == 30) {
              Yxiasoptions.grid.bottom =
                parseInt(Number(this.maxyAxisNameGap) / 2) + 32;
            } else {
              Yxiasoptions.grid.bottom = 50;
            }
          }
          Yxiasoptions.grid.containLabel = false;
        } else if (Yxiasoptions.legend && Yxiasoptions.legend.width) {
          // 判断是否有图例
          // 双轴图和同环比双轴图在X轴没有标签名
          if (
            this.dashboardtype == "biaxialFigure" ||
            this.dashboardtype == "biaxial"
          ) {
            if (isRotate == 30) {
              Yxiasoptions.grid.bottom =
                parseInt(Number(this.maxyAxisNameGap) / 2) + 25;
            } else {
              Yxiasoptions.grid.bottom = 50;
            }
          } else if (this.dashboardtype == "waterfall") {
            if (isRotate == 30) {
              Yxiasoptions.grid.bottom =
                parseInt(Number(this.maxyAxisNameGap) / 2) + 55;
            } else {
              Yxiasoptions.grid.bottom = 85;
            }
          } else {
            if (isRotate == 30) {
              Yxiasoptions.grid.bottom =
                parseInt(Number(this.maxyAxisNameGap) / 2) + 40;
            } else {
              Yxiasoptions.grid.bottom = 70;
            }
          }
          Yxiasoptions.legend.bottom = 0;
          Yxiasoptions.grid.containLabel = false;
        } else {
          if (isRotate == 30) {
            Yxiasoptions.grid.bottom =
              parseInt(Number(this.maxyAxisNameGap) / 2) + 20;
          } else {
            Yxiasoptions.grid.bottom = 50;
          }
          Yxiasoptions.grid.containLabel = false;
        }
      } else {
        // Y轴超过10个字显示省略号
        Yxiasoptions.yAxis.nameGap = this.maxyAxisNameGap;
        Yxiasoptions.yAxis.axisLabel = {
          formatter: (value) => this.interceptStr(value), //截取字符串
        };
      }
    },
    // 计算图表中每个图形的最小宽度
    // 1、仪表板中每个图表根据dom获取宽度
    // 2、全屏时根据window计算图表宽度
    // 3、组件编辑器时计算图表宽度
    calculationMinWidth(labelData) {
      let labelDataLength = labelData.length ? labelData.length : 1;
      if (this.$refs.myecharts) {
        this.chartFiledLength = parseInt(
          (this.$refs.myecharts.$el.clientWidth * 0.7) / labelDataLength
        );
      } else if (
        // 点击全屏时可能获取不到dom元素通过浏览器宽度进行计算
        !this.$refs.myecharts &&
        this.useLocation === "chartFullScreen"
      ) {
        this.chartFiledLength = parseInt(
          (parseInt(window.innerWidth * 0.85) * 0.7) / labelDataLength
        );
      } else if (this.isShowComponentsEditorAlert) {
        this.chartFiledLength = parseInt(
          (parseInt(518 * 0.94) * 0.7) / labelDataLength
        );
      }
    },
    // 计算字符串宽度（字符串，字号）
    getTextWidth(text, font) {
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      context.font = font;
      var metrics = context.measureText(text);
      return parseInt(metrics.width);
    },
    // 联动状态下点击添加背景色
    chooseChartBack(data, type) {
      let colorData = [];
      if (this.status === "1" && this.useLocation != "chartFullScreen") {
        data.map((item, index) => {
          if (type) {
            if (index === this.chooseCurrentIndex) {
              colorData.push("rgba(180, 180, 180, 0.2)");
            } else {
              colorData.push("rgba(180, 180, 180, 0.01)");
            }
          } else {
            if (index === this.chooseCurrentIndex) {
              colorData.push("rgba(180, 180, 180, 0.2)");
            } else {
              colorData.push("#FFF");
            }
          }
        });
        if (colorData === []) {
          if (type) {
            colorData.push("rgba(180, 180, 180, 0.01)");
          } else {
            colorData.push("#FFF");
          }
          return colorData;
        } else {
          return colorData;
        }
      } else {
        colorData.push("rgba(180, 180, 180, 0.01)");
        return colorData;
      }
    },
    // 图表显示单位
    chartShowCompany() {
      if (this.unit == "ten thousand") {
        //万
        return "  (" + this.$i18n.t("label.dashboard.unit1") + ")"; //"  单位: 万";
      } else if (this.unit == "million") {
        //百万
        return "  (" + this.$i18n.t("label.dashboard.unit2") + ")"; //"  单位:百万";
      } else if (this.unit == "ten million") {
        //千万
        return "  (" + this.$i18n.t("label.dashboard.unit4") + ")"; //"  单位:千万";
      } else if (this.unit == "billion") {
        //亿
        return "  (" + this.$i18n.t("label.dashboard.unit5") + ")"; //"  单位:亿";
      } else {
        return "";
      }
    },
    // 数字向上取整，整百整千
    toNumber(max) {
      let bite = 0;
      if (max < 10) {
        return 10;
      }
      while (max >= 10) {
        max /= 10;
        bite += 1;
      }
      return Math.ceil(max) * Math.pow(10, bite);
    },
    /**
     * 双轴图数据处理，根据汇总方式，获取数据所在位置
     */
    biaxialFigureData(type, index, value) {
      let schemefield = ""; //对应字段
      let FieldList = ""; //
      let name = "";
      switch (type) {
        case "max": //最大值
          FieldList = "listMaxFields";
          break;
        case "min": //最小值
          FieldList = "listMinFields";
          break;
        case "sum": //总和
          FieldList = "listSumFields";
          break;
        case "count": //记录数
          FieldList = "mapCountField";
          break;
        case "avg": //平均值
          FieldList = "listAvgFields";
          break;
      }
      // 新建报表：关闭组件编辑器回显到页面图表时获取name和value
      let showDataFild = [];
      showDataFild = this.showData[FieldList];
      // 统计值为记录数，mapCountField拼接到数组
      if (this.showData.mapCountField) {
        showDataFild.push(this.showData.mapCountField);
      }
      // 根据统计值查找name和value
      showDataFild.map((item) => {
        if (value === item.id) {
          name = item.labelName;
          schemefield = item.schemefield_name_alias;
        }
      });
      this.listGroupData.push({
        name: name,
        value: this.listChartData[0][schemefield],
      });
    },
    /**
     * echarts点击事件
     * @param {params} 点击选中的数据
     */
    linkagegetData(params) {
      // 主页中销售看板组件中的漏斗，点击需要跳转至销售漏斗菜单
      if (this.dashboardtype === "funnel") {
        this.$emit("clickFunnel");
        this.$emit("linkagegetData", params);
      }
      // 热力图数据中的name经过了特殊处理，所以需要对name重新赋原来的值
      if (this.dashboardtype === "heatMap") {
        if (!params.data) return;
        params.name = params.data.names;
        this.$emit("linkagegetData", params);
      }
      // 矩阵树形图添加高亮后会导致背景色变成白色
      if (this.dashboardtype != "matrixTree") {
        let myChart = this.$refs.myecharts;
        // 1、首次点击图表进行联动时，高亮显示点击部分
        // 2、再次点击同一图表，同一位置时，取消联动
        // 3、点击其他图表时再次进行联动
        if (
          this.chooseCurrentType == params.seriesType &&
          this.chooseCurrentIndex == params.dataIndex
        ) {
          this.chooseCurrentIndex = "";
          this.chooseCurrentType = "";
          myChart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
          });
          this.$store.commit("setChartLinkageContent", "");
          this.$emit("linkagegetData", params, "cancellinkage");
        } else {
          this.chooseCurrentIndex = params.dataIndex;
          this.chooseCurrentType = params.seriesType;
          myChart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
          });
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: params.dataIndex,
          });
          // 联动提示内容
          let title = "";
          if (this.mapGroupOneFieldLabelName) {
            title = this.mapGroupOneFieldLabelName + "=" + params.name;
          } else if (this.mapSummaryFieldLabelName) {
            title = this.mapSummaryFieldLabelName + "=" + params.name;
          }
          this.$store.commit("setChartLinkageContent", title);
          this.$emit("linkagegetData", params);
        }
      } else {
        this.$emit("linkagegetData", params);
      }
    },
    /**
     * 渲染图表
     */
    drawChart() {
      switch (this.dashboardtype) {
        case "bar_0": // 1--type: "bar_0", //没有第二个分组的条形图
          this.getOptionsBar_0();
          this.setBarChartsYxias(
            this.options,
            this.ischangeYesOrNo(this.groupOneArr),
            "y"
          );
          // 勾选显示数据就对图表样式进行处理
          if(this.isshowvalue=='true'){
            this.setBarTypeChart(this.options)
          }
          break;
        case "column_0": // 2--type: "column_0", //没有第二个分组的柱状图
          this.getOptionsColumn_0();
          this.changeAxisName(false, this.options);
          if(this.isshowvalue=='true'){
            this.setColumnTypeChart(this.options)
          }
          break;
        case "waterfall": // 3--type: "waterfall", //没有第二个分组的柱状图类型中的瀑布图
          this.getOptionsWaterfall();
          this.changeAxisName(false, this.options);
          break;
        case "line_0": // 4--type: "line_0", //没有第二个分组的折线图
          this.getOptionsLine_0();
          this.changeAxisName(false, this.options);
          break;
        case "pie": //5--type: "pie", //饼图
          this.getOptionsPie();
          this.isShowValuePercent(this.options);
          break;
        case "donut": // 6--type: "donut", //圆环图
          this.getOptionsDonut();
          this.isShowValuePercent(this.options);
          break;
        case "funnel": // 7--type: "funnel", //漏斗图
          // this.getOptionsFunnel();

          this.getFunnel();
          break;
        case "gauge": // 8--type:"gauge" //指针图
          this.getOptionsGauge();
          break;
        case "line_1": // 9--type: "line_1", //有第二个分组的折线图
          this.getOptionsLine_1();
          this.changeAxisName(false, this.options);
          break;
        case "column_duidie": // 10--type: "column_duidie", //柱状堆叠图
          this.getOptionsColumn_duidie();
          this.changeAxisName(false, this.options);
          break;
        case "bar_duidie": // 11--type: "bar_duidie", //条形堆叠图
          this.getOptionsBar_duidie();
          this.changeAxisName(true, this.options);
          this.setBarChartsYxias(
            this.options,
            this.ischangeYesOrNo(this.groupOneArr),
            "y"
          );
          break;
        case "area": // 12--type: "area", //面积堆叠图
          this.getOptionsArea();
          break;
        case "column_1": // 13--type: "column_1", //有第二个分组的柱状图
          this.getOptionsColumn_1();
          this.changeAxisName(false, this.options);
          break;
        case "bar_1": // 14--type: "bar_1", //有第二个分组的条形图
          this.getOptionsBar_1();
          this.changeAxisName(true, this.options);
          this.setBarChartsYxias(
            this.options,
            this.ischangeYesOrNo(this.groupOneArr),
            "y"
          );
          break;
        case "heatMap": // 17--type: "heatMap", //没有第二个分组的热力图
          // eslint-disable-next-line no-case-declarations
          let chinaMap = [];
          if (this.$store.state.userInfoObj.language == "zh") {
            chinaMap = chinaChinese;
          } else {
            chinaMap = chinaEnglish;
          }
          this.getOptionsHeatMap(chinaMap);
          break;
        case "worldMap": // 17--type: "worldMap", //没有第二个分组的世界地图
          // eslint-disable-next-line no-case-declarations
          let axiox = require("axios");
          // eslint-disable-next-line no-case-declarations
          let origin = window.location.origin;
          // eslint-disable-next-line no-case-declarations
          let worldLanguage = "";
          if (this.$store.state.userInfoObj.language == "zh") {
            worldLanguage = "/cloudcc-json/worldChinese.json";
          } else {
            worldLanguage = "/cloudcc-json/worldEnglish.json";
          }
          axiox.get(origin + worldLanguage).then((res) => {
            this.getOptionsWordMap(res.data);
            this.$emit("maploading");
          });
          break;
        case "usaMap": // 17--type: "worldMap", //没有第二个分组的美国地图
          // eslint-disable-next-line no-case-declarations
          let axio = require("axios");
          // eslint-disable-next-line no-case-declarations
          let usaorigin = window.location.origin;
          // eslint-disable-next-line no-case-declarations
          let usaLanguage = "";
          if (this.$store.state.userInfoObj.language == "zh") {
            usaLanguage = "/cloudcc-json/usaMapChinese.json";
          } else {
            usaLanguage = "/cloudcc-json/MapusaEnglish.json";
          }
          axio.get(usaorigin + usaLanguage).then((res) => {
            this.getOptionsUsaMap(res.data);
            this.$emit("maploading");
          });
          break;
        case "britainMap": //英国
          this.getMapCase("britainMap", "UK");
          break;
        case "singaporeMap": //新加坡
          this.getMapCase("singaporeMap", "Singapore");
          break;
        case "mexicoMap": //墨西哥
          this.getMapCase("mexicoMap", "Mexico");
          break;
        case "canadaMap": //加拿大
          this.getMapCase("canadaMap", "Canada");
          break;
        case "franceMap": //法国
          this.getMapCase("franceMap", "FR");
          break;
        case "germanyMap": //德国
          this.getMapCase("germanyMap", "DE");
          break;
        case "australiaMap": //澳大利亚
          this.getMapCase("australiaMap", "Australia");
          break;
        case "matrixTree": // 18--type: "matrixTree", // 矩阵树形图
          this.getOptionsMatrixTree();
          break;
        case "radarMap": // 19--type: "radarMap", // 雷达图
          this.getOptionsRadarMap();
          break;
        case "bubble": // 20--type: "bubble", // 气泡图
          this.getOptionsBubble();
          break;
        case "biaxialFigure": // 21--type: "biaxialFigure", // 双轴图
          // 判断统一刻度复选框是否勾选
          if(this.BiaxialFigureCheck){
            this.getOptionsBiaxialFigure("dynamic");
          }else{
            this.getOptionsBiaxialFigure();
          }
          break;
        case "target": // 22--type: "target", // 目标图
          this.getOptionsTarget();
          break;
        case "biaxial": // 24--type: "biaxial", // 同环比双轴图
          this.getOptionsBiaxial();
          break;
      }
    },
    /**
     * 根据返回的统计类型value获取对应的显示label
     */
    getSummaryWayLabel() {
      // 获取字段统计方式的label
      let fieldSummaryWay = "";
      if (!this.mapSummaryWay) return fieldSummaryWay;
      if (this.mapSummaryWay === "count") {
        // 如果统计方式为count，则表示为记录数，不需显示单位；
        fieldSummaryWay = "";
      } else {
        // 若为某个字段的统计值，正常显示
        fieldSummaryWay =
          statisticsTypes().find((item) => item.value === this.mapSummaryWay)
            .label || "";
      }
      // 英文情况下单词拼接时加空格
      if (this.$store.state.userInfoObj.language == "zh") {
        return fieldSummaryWay;
      } else {
        return " " + fieldSummaryWay;
      }
    },
    /**
     * 修改坐标轴名称
     * @param {flag} true：一级分组字段信息在y ，false：一级分组字段信息在x
     * @param {options} 坐标轴选项设置
     */
    changeAxisName(flag, options) {
      let fieldSummaryWay = this.getSummaryWayLabel(); // 统计类型label
      if (flag) {
        if (!options.xAxis.title) {
          options.xAxis.name =
            `${this.mapSummaryFieldLabelName}${fieldSummaryWay}` +
            this.chartShowCompany();
          options.yAxis.name = this.mapGroupOneFieldLabelName;
          return;
        }
        options.xAxis.title.text = `${this.mapSummaryFieldLabelName}${fieldSummaryWay}`;
        options.xAxis.title.text = this.mapGroupOneFieldLabelName;
      } else {
        if (!options.xAxis.title) {
          options.xAxis.name = this.mapGroupOneFieldLabelName;
          options.yAxis.name =
            `${this.mapSummaryFieldLabelName}${fieldSummaryWay}` +
            this.chartShowCompany();
          return;
        }
        options.xAxis.title.text = this.mapGroupOneFieldLabelName;
        options.yAxis.title.text =
          `${this.mapSummaryFieldLabelName}${fieldSummaryWay}` +
          this.chartShowCompany();
      }
    },
    /**
     * 世界地图和美国地图鼠标悬浮显示提示信息
     * @param {val} 提示信息
     */
    worldMapShowFormatter(val) {
      if (val.name && val.value) {
        return `${val.name} <br /> ${this.mapSummaryFieldLabelName}: ${val.value}`;
      } else {
        return ` ${val.name}<br /> ${this.mapSummaryFieldLabelName} : 0`;
      }
    },
    /**
     * 热力图鼠标放上去控制显示内容
     */
    heatMapShowFormatter(params) {
      let fieldSummaryWay = this.getSummaryWayLabel(); // 统计类型label
      if (params.seriesType == "scatter") {
        return `${params.name}<br />${this.mapSummaryFieldLabelName}${fieldSummaryWay}：${params.value[2]}`;
      } else if (params.seriesType == "map") {
        if (params.data != undefined) {
          return `${params.name}<br />${this.mapSummaryFieldLabelName}${fieldSummaryWay}：${params.value}`;
        } else {
          return params.name + "：" + "-";
        }
      }
    },
    /**
     * 数组去重累加
     */
    mapDataQu(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].name == arr[j].name) {
            arr[i].value[2] = Number(arr[j].value[2]) + Number(arr[i].value[2]);
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },
    /**
     * 获取经纬度最大值
     * @param {arr} 经纬度坐标数据
     */
    mapDataMax(arr) {
      this.mapValueArr = [];
      // 根据显示的是国家、省、市查询对应的最大值
      if (this.mapTypes === "map") {
        let provinceName = [];
        if (this.$store.state.userInfoObj.language == "zh") {
          provinceName = provinceChinese;
        } else {
          provinceName = provinceEnglish;
        }
        arr.map((item) => {
          provinceName.map((item1) => {
            if (item.name == item1.name) {
              this.mapValueArr.push(item.value);
            }
          });
        });
      } else if (this.mapTypes === "country") {
        arr.map((item) => {
          if (item.name != "-") {
            this.mapValueArr.push(item.value);
          }
        });
      } else if (this.mapTypes === "scatter") {
        let cityLanguage = [];
        if (this.$store.state.userInfoObj.language == "zh") {
          cityLanguage = cityChinese;
        } else {
          cityLanguage = cityEnglish;
        }
        arr.map((item) => {
          cityLanguage.map((item1) => {
            if (item.name == item1.name) {
              this.mapValueArr.push(item.value[2]); // 市的value是一个数组，前两项为它的经纬度
            }
          });
        });
      }
      // 判断mapValueArr是否为空数组，若是空数组则直接设置最大值默认为1
      if (this.mapValueArr.length > 0) {
        // 修改一下最大值的取值方式：取所有可显示的统计值排序后的中位数值*2作为最大值。
        this.mapValueArr.sort((a, b) => a - b); // 排序
        let lengthMedian = Math.floor(this.mapValueArr.length / 2); // 取中间长度的index
        let mapMedian = this.mapValueArr[lengthMedian]; // 取中位数
        // 中位数*2小于最大数时，取最大数
        if (mapMedian * 2 < this.mapValueArr[this.mapValueArr.length - 1]) {
          this.mapMax = this.mapValueArr[this.mapValueArr.length - 1];
        } else {
          this.mapMax = mapMedian * 2;
        }
        if (this.mapMax == 0) {
          this.mapMax = 1;
        }
        return;
      }
      this.mapMax = 1;
    },
    /**
     * 对热力图中数据是否能显示做处理
     * @param {name} 要显示的数据
     */
    dealName(name) {
      // 判断最后一个字是不是"省",是的话,"省"字截取掉
      let mapNameLast = name.substr(name.length - 1, 1);
      if (mapNameLast == "省") {
        // 省
        name = name.split("省").join("");
      } else if (name.indexOf("Province") > -1) {
        // 英文：去掉Province和空格
        name = name.replace(/Province/, "");
        name = name.replace(/(^\s*)|(\s*$)/g, "");
      } else if (mapNameLast == "市") {
        // 市
        name = name.split("市").join("");
      }
      return name;
    },
    /**
     * 判断数据标签中是否需要显示数值及百分比
     * @param {options} 标签中的数据
     */
    isShowValuePercent(options) {
      let companyUnit = this.pieChartsUnit(); //获取单位
      if (this.isshowpercent === "true" && this.isshowvalue === "true") {
        options.series[0].label.formatter = function (params) {
          return `${params.name} : ${params.value}${companyUnit}(${params.percent}%)`;
        };
        options.tooltip.formatter = function (params) {
          return `${params.name}<br />${params.marker}<b>${params.value}${companyUnit}(${params.percent}%)`;
        };
      } else if (
        this.isshowpercent === "true" &&
        this.isshowvalue === "false"
      ) {
        options.series[0].label.formatter = "{b}\n占比：{d}%\n ";
        options.tooltip.formatter = function (params) {
          return `${params.name}<br />${params.marker} 占比：<b>${params.percent}%`;
        };
      } else if (
        this.isshowpercent === "false" &&
        this.isshowvalue === "true"
      ) {
        options.series[0].label.formatter = function (params) {
          return `${params.name} : ${params.value}${companyUnit}`;
        };
        options.tooltip.formatter = function (params) {
          return `${params.name}<br />${params.marker} <b>${params.value}${companyUnit}`;
        };
      } else {
        options.series[0].label.formatter = "{b} \n  ";
        options.tooltip.formatter = function (params) {
          return `${params.marker} ${params.name}`;
        };
      }
    },
    /**
     * 将数值转换为千分位
     *  @param {num} 要处理的数据
     */
    changeMicrometerLevel(num) {
      if (num) {
        var parts = num.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    },
     /**
     * 数字保留一位小数
     */
    handleNumber(data,maxvalue,value){
      let strData=data.toString();
      let index=strData.indexOf(".");
      if(index>-1&& value<maxvalue){
        return Number(strData.substring(0,index+2))
      }else{
        return Number(strData)
      }
    },
    /**
     * 加单位K，M，G
     * @param {value} 要处理的数据
     * maxvalue:为指针图特有数据
     */
    pushCompany(value,maxvalue) {
      if(maxvalue){
        let num = 1000.0; //byte
        if (value <= num) return this.handleNumber(value,maxvalue,value); //千以内
        if (value > num && value < Math.pow(num, 2)) return this.handleNumber(value / num,maxvalue,value) + "K"; //千
        if (value > num && value < Math.pow(num, 3))
          return this.handleNumber(value / Math.pow(num, 2),maxvalue,value)+ "M"; //百万.toFixed(1)
        if (value > num && value < Math.pow(num, 4))
          return this.handleNumber(value / Math.pow(num, 3),maxvalue,value) + "G"; //十亿
        return this.handleNumber(value / Math.pow(num, 4),maxvalue,value) + "T";
      }else{
        let num = 1000.0; //byte
        if (value <= num) return value; //千以内
        if (value > num && value < Math.pow(num, 2)) return value / num + "K"; //千
        if (value > num && value < Math.pow(num, 3))
          return value / Math.pow(num, 2) + "M"; //百万.toFixed(1)
        if (value > num && value < Math.pow(num, 4))
          return value / Math.pow(num, 3) + "G"; //十亿
        return value / Math.pow(num, 4) + "T";
      }
    },
    // 当页面显示不足时，设置滚动条，图形最小宽度12，图形左右间距是50%，图形宽度为28
    // 获取图表的宽度，图表左右有10%的间隔，图表width*0.7/28/数据总数*100=宽度为12时，图表最多可以显示百分之几的图形
    setChartZoom(dataRotate) {
      // 在全屏情况下如果总数小于20就显示100%的数据，大于20计算百分比
      if (this.useLocation === "chartFullScreen") {
        if (dataRotate.length > 20) {
          if (this.$refs.myecharts && this.$refs.myecharts.$el) {
            this.zoomMinSpan = parseInt(
              ((this.$refs.myecharts.$el.clientWidth * 0.7) /
                30 /
                dataRotate.length) *
                100
            );
          } else {
            this.zoomMinSpan = parseInt(
              (parseInt(parseInt(window.innerWidth * 0.8) * 0.7) /
                30 /
                dataRotate.length) *
                100
            );
          }
        } else {
          this.zoomMinSpan = 100;
        }
      } else {
        // 图表数量大于5时，计算百分比
        if (dataRotate.length <= 5) {
          this.zoomMinSpan = 100;
        } else if (this.$refs.myecharts) {
          if (this.$refs.myecharts && this.$refs.myecharts.$el) {
            this.zoomMinSpan = parseInt(
              ((this.$refs.myecharts.$el.clientWidth * 0.7) /
                30 /
                dataRotate.length) *
                100
            );
          }
          if (
            this.dashboardtype == "biaxialFigure" ||
            this.dashboardtype == "waterfall"
          ) {
            this.zoomMinSpan = (this.zoomMinSpan / 3) * 2;
          }
        } else {
          this.zoomMinSpan = parseInt(
            ((parseInt(518 * 0.94) * 0.7) / 30 / dataRotate.length) * 100
          );
        }
      }
    },
    // 计算标签名称是否需要倾斜显示
    chartColumnXRotate(dataRotate) {
      let datalength = 0;
      dataRotate.map((item) => {
        if (datalength < item.length) {
          datalength = item.length;
        }
      });
      let filedLength = 0; //图表内可展示的字段长度
      this.chartFiledLength = 0;
      if (this.$refs.myecharts && this.$refs.myecharts.$el) {
        filedLength = parseInt(
          (this.$refs.myecharts.$el.clientWidth * 0.8) / dataRotate.length / 12
        );
      }
      if (datalength < filedLength) {
        return 0;
      } else {
        return 30;
      }
    },
    // 计算标签名称和坐标名的距离
    chartColumnXinterval(intervalData) {
      let interval = 0;
      let width = 4;
      if (
        this.showData &&
        this.showData.chartInfo &&
        this.showData.chartInfo.width
      ) {
        width = this.showData.chartInfo.width;
      } else {
        width = 4;
      }
      if (Number(width) + 6 > intervalData.length) {
        return 0;
      } else {
        if (this.useLocation === "chartFullScreen") {
          interval = parseInt(intervalData.length / (width * 6));
          if (interval <= 0.7) {
            interval = 1;
          }
        } else {
          if (this.dashboardtype != "waterfall") {
            interval = parseInt(intervalData.length / (width * 4)) + 1;
          } else {
            interval = parseInt(intervalData.length / (width * 3)) + 1;
          }
        }
        return interval;
      }
    },
    /**
     * 坐标轴标签多行展示
     * @param {value} 坐标轴标签内容
     * @param {index} 显示行数
     */
    // 坐标轴标签多行展示
    multiLine(value, index) {
      // 两行
      if (index % 2 != 0) {
        return value.length > 15
          ? "\n " + value.slice(0, 15) + ".."
          : "\n " + value;
      } else {
        return value.length > 15 ? value.slice(0, 15) + ".." : value;
      }
    },
    /**
     * 切换主题时highcharts颜色改变
     * @param {options} 颜色设置对象
     */
    highchartsTheme(options) {
      // x轴文字、标题颜色
      options.xAxis.title.style = {
        color: this.themeColor === "dark" ? "#B9B8CE" : "#6E7079",
      };
      options.xAxis.labels.style = {
        color: this.themeColor === "dark" ? "#B9B8CE" : "#6E7079",
      };
      // y轴文字、标题、网格线颜色
      options.yAxis.title.style = {
        color: this.themeColor === "dark" ? "#B9B8CE" : "#6E7079",
      };
      options.yAxis.labels.style = {
        color: this.themeColor === "dark" ? "#B9B8CE" : "#6E7079",
      };
      options.yAxis.gridLineColor =
        this.themeColor === "dark" ? "#484753" : "#E0E6F1";
      // 图例文字、文字激活时颜色、切换页颜色
      options.legend.itemStyle = {
        color: this.themeColor === "dark" ? "#B9B8CE" : "#6E7079",
      };
      options.legend.itemHoverStyle = {
        color: this.themeColor === "dark" ? "#B9B8CE" : "#6E7079",
      };
      if (options.legend.navigation) {
        options.legend.navigation.style = {
          color: this.themeColor === "dark" ? "#B9B8CE" : "#6E7079",
        };
      }
    },
    /**
     * 没有第二个分组的条形图 1--type: "bar_0"
     */
    getOptionsBar_0() {
      let that = this;
      let colorData = this.chooseChartBack(this.groupOneArr);
      this.options = {
        tooltip: {
          trigger: "axis",
          confine: true, //将此限制打开后tooltip将不再溢出
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          name:
            this.mapSummaryFieldLabelName +
            this.getSummaryWayLabel() +
            that.chartShowCompany(),
          nameLocation: "middle",
          nameGap: 30,
          type: "value",
          boundaryGap: [0, 0.01],
          axisLabel: {
            // interval: 3,//设置X轴间隔数据
            formatter: (value) => that.pushCompany(value),
          },
        },
        grid: {
          top: "2%",
          left: "10%",
          right: "8%",
          bottom: "30",
          containLabel: true,
        },
        yAxis: {
          name: this.mapGroupOneFieldLabelName,
          nameLocation: "middle",
          type: "category",
          inverse: true, // 设置图表默认排序方式
          data: this.ischangeYesOrNo(this.groupOneArr),
          splitArea: {
            interval: "0",
            show: true,
            areaStyle: {
              color: colorData, //这里重点，用于设置颜色的数组，
            },
          },
        },
        series: [
          {
            type: "bar",
            center: ["50%", "60%"], //位于图表的位置
            data: this.ismodifyData(this.groupOneArr, this.valueDataArr),
            itemStyle: {
              normal: {
                color: "#788EE0", //圈圈的颜色
                lineStyle: {
                  color: "#788EE0", //线的颜色
                },
              },
            },
          },
        ],
      };
    },
    /**
     * 没有第二个分组的柱状图 2--type: "column_0"
     */
    getOptionsColumn_0() {
      let that = this;
      let dataArr = [];
      this.groupOneArr.map((item, index) => {
        dataArr.push([item, this.valueDataArr[index]]);
      });
      let colorData = this.chooseChartBack(dataArr, "column_0");
      this.options = {
        tooltip: {
          trigger: "axis",
          confine: true, //将此限制打开后tooltip将不再溢出
          axisPointer: {
            type: "shadow",
          },
        },
        color: ["#FFB657"],
        xAxis: {
          name: this.mapGroupOneFieldLabelName,
          nameLocation: "middle",
          type: "category",
          data: this.ischangeYesOrNo(this.groupOneArr),
          splitArea: {
            interval: "0", //设置间隔为0
            show: true,
            areaStyle: {
              color: colorData, //这里重点，用于设置颜色的数组，
            },
          },
        },
        yAxis: {
          type: "value",
          name: this.mapSummaryFieldLabelName,
          nameLocation: "middle",
          nameGap: 40,
          axisLabel: {
            formatter: (value) => that.pushCompany(value),
          },
        },
        grid: {
          top: "4%",
          right: "8%",
          left: "10%",
          containLabel: false,
        },
        series: [
          {
            data: this.ismodifyData(this.groupOneArr, this.valueDataArr),
            type: "bar",
            itemStyle: {
              normal: {
                color: "#FFB657", //圈圈的颜色
                lineStyle: {
                  color: "#FFB657", //线的颜色
                },
              },
            },
            // emphasis: {
            //   itemStyle: {
            //     shadowBlur: 10,
            //     color: "#FFB657",
            //     shadowOffsetX: 0,
            //     shadowColor: "rgba(0, 0, 0, 0.5)",
            //   },
            // },
          },
        ],
      };
      this.setBarChartsYxias(
        this.options,
        this.ischangeYesOrNo(this.groupOneArr),
        "x"
      );
    },
    /**
     * 没有第二个分组的柱状图类型中的瀑布图 3--type: "waterfall"
     */
    getOptionsWaterfall() {
      //数据
      const arr = this.valueDataArr;
      //收入
      const income = [arr[0]];
      // 支出
      const expenditure = ["-"];
      // 辅助
      const auxiliary = [0];
      //计算数据
      arr.map((item, index) => {
        if (arr[index + 1] - item >= 0) {
          income.push(arr[index + 1] - item);
          expenditure.push("-");
          auxiliary.push(arr[index]);
        } else {
          income.push("-");
          expenditure.push(-(arr[index + 1] - item));
          auxiliary.push(arr[index + 1]);
        }
      });
      income.pop();
      expenditure.pop();
      auxiliary.pop();

      let that = this;
      this.options = {
        color: [
          "#7F8DE1",
          "#FCB95C",
          "#4087F2",
          "#59B9FF",
          "#2CC7BE",
          "#93D053",
          "#F6CA25",
          "#DA4E2E",
          "#F3768A",
        ],
        backgroundColor: "transparent",
        grid: {
          containLabel: true,
          right: "8%",
          top: "6%",
          left: "10%",
        },
        tooltip: {
          trigger: "axis",
          confine: true, //将此限制打开后tooltip将不再溢出
          axisPointer: {
            type: "shadow",
          },
          enterable: true,
          textStyle: {
            fontSize: 12,
          },
          formatter: function (params) {
            // 上升或下降
            let upOrDown;
            if (params[1].value !== "-") {
              upOrDown = params[1];
            } else {
              upOrDown = params[2];
            }
            // 总和
            let sum = params[0];
            sum.value =
              params[1].value !== "-" ? sum.value + upOrDown.value : sum.value;
            //第一个提示框只显示总和
            return sum.value - upOrDown.value === 0 //先用这个没找到合适的条件
              ? `${sum.name}<br />${sum.seriesName}:${sum.value.toFixed(2)}`
              : `${sum.name}<br />${sum.seriesName}:${sum.value}<br />${
                  upOrDown.seriesName
                }:${upOrDown.value.toFixed(2)}`;
          },
        },
        legend: {
          data: [
            this.$i18n.t("vue_label_fall"),
            this.$i18n.t("vue_label_rise"),
          ],
          type: "scroll",
          pageIconColor: this.themeColor === "dark" ? "#aaa" : "#2f4554",
          pageIconInactiveColor:
            this.themeColor === "dark" ? "#2f4554" : "#aaa",
          width: "80%",
          pageTextStyle: {
            color: this.themeColor === "dark" ? "#B9B8CE" : "#6E7079",
          },
        },
        xAxis: {
          name: "",
          offset: 15, //坐标轴数据和X轴的距离
          nameLocation: "middle",
          type: "category",
          data: this.ischangeYesOrNo(this.groupOneArr),
        },
        yAxis: {
          name: "",
          nameLocation: "middle",
          nameGap: 40,
          type: "value",
          axisLabel: {
            formatter: (value) => that.pushCompany(value),
          },
        },
        series: [
          {
            name: this.$i18n.t("vue_label_total"),
            type: "bar",
            stack: "总量",
            itemStyle: {
              barBorderColor: "rgba(0,0,0,0)",
              color: "rgba(0,0,0,0)",
            },
            emphasis: {
              itemStyle: {
                barBorderColor: "rgba(0,0,0,0)",
                color: "rgba(0,0,0,0)",
              },
            },
            data: auxiliary,
          },
          {
            barMinHeight: 2.5,
            name: this.$i18n.t("vue_label_rise"),
            type: "bar",
            stack: "总量",
            label: {
              show: true,
              position: "top",
              formatter: function (value) {
                let index = that.groupOneArr.findIndex(
                  (item) => item === value.name
                );
                return that.valueDataArr[index];
              },
            },
            data: income,
          },
          {
            barMinHeight: 2.5,
            name: this.$i18n.t("vue_label_fall"),
            type: "bar",
            stack: "总量",
            label: {
              show: true,
              position: "bottom",
              formatter: function (value) {
                let index = that.groupOneArr.findIndex(
                  (item) => item === value.name
                );
                return that.valueDataArr[index];
              },
            },
            data: expenditure,
          },
        ],
      };
      this.setBarChartsYxias(this.options, this.groupOneArr, "x");
    },
    /**
     * 没有第二个分组的折线图 4--type: "line_0"
     */
    getOptionsLine_0() {
      let that = this;
      let colorData = this.chooseChartBack(this.groupOneArr, "line_0");
      this.options = {
        color: "#00A1E0",
        backgroundColor: "transparent",
        grid: {
          right: "8%",
          left: "10%",
          top: "4%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          confine: true, //将此限制打开后tooltip将不再溢出
          textStyle: {
            fontSize: 14,
          },
          enterable: true,
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          type: "scroll",
          pageIconColor: this.themeColor === "dark" ? "#aaa" : "#2f4554",
          pageIconInactiveColor:
            this.themeColor === "dark" ? "#2f4554" : "#aaa",
          pageTextStyle: {
            color: this.themeColor === "dark" ? "#B9B8CE" : "#6E7079",
          },
        },
        xAxis: {
          name: "",
          nameLocation: "middle",
          type: "category",
          data: this.ischangeYesOrNo(this.groupOneArr),
          splitArea: {
            interval: "0",
            show: true,
            areaStyle: {
              color: colorData, //这里重点，用于设置颜色的数组，
            },
          },
        },
        yAxis: {
          name: "",
          nameLocation: "middle",
          nameGap: 40,
          type: "value",
          axisLabel: {
            formatter: (value) => that.pushCompany(value),
          },
        },
        series: [
          {
            data: this.ismodifyData(this.groupOneArr, this.valueDataArr),
            type: "line",
            itemStyle: {
              normal: {
                color: "#7F8DE1", //圈圈的颜色
                lineStyle: {
                  color: "#7F8DE1", //线的颜色
                },
              },
            },
          },
        ],
      };
      this.setBarChartsYxias(this.options, this.groupOneArr, "x");
    },
    /**
     * 饼图 5--type: "pie"
     */
    getOptionsPie() {
      this.options = {
        color: [
          "#FCB95C",
          "#7F8DE1",
          "#2CC7BE",
          "#4087F2",
          "#93D053",
          "#F6CA25",
          "#875AE6",
          "#F3768A",
          "#DA4E2E",
        ],
        backgroundColor: "transparent",
        title: {
          text: "",
          subtext: "",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}：<br />{c}({d}%)",
          confine: true, //将此限制打开后tooltip将不再溢出
          textStyle: {
            fontSize: 12,
          },
        },
        series: [
          {
            type: "pie",
            avoidLabelOverlap: true, //是否启用防止标签重叠策略，默认开启，
            radius: ["0%", "50%"],
            center: ["50%", "50%"],
            data: this.ischangeYesOrNo(this.dataArr),
            label: {
              overflow: "break",
              width: Number(this.pieWidth)>5?Number(this.pieWidth):100,
              formatter: "{b}：\n{c}({d}%) ",
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    /**
     * 圆环图 6--type: "donut"
     */
    getOptionsDonut() {
      this.options = {
        color: [
          "#FCB95C",
          "#7F8DE1",
          "#2CC7BE",
          "#4087F2",
          "#93D053",
          "#F6CA25",
          "#F3768A",
          "#DA4E2E",
          "#875AE6",
        ],
        backgroundColor: "transparent",
        tooltip: {
          trigger: "item",
          confine: true, //将此限制打开后tooltip将不再溢出
          formatter: "{b}：<br />{c}({d}%)",
          // eslint-disable-next-line no-dupe-keys
          confine: true, //将此限制打开后tooltip将不再溢出
          textStyle: {
            fontSize: 12,
          },
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["35%", "50%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: true,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              overflow: "breakAll",
              formatter: "{b}\n ",
              fontSize: 12,
            },
            data: this.ischangeYesOrNo(this.dataArr),
          },
        ],
      };
    },

    /**
     * 漏斗图 7--type: "funnel"
     */
    getFunnel() {
      let arr = this.dataArr.map((item) => {
        return [item.name, Number(item.value)];
      });
      let company = this.pieChartsUnit();
      let that = this;
      this.options = {
        chart: {
          type: "funnel",
          marginLeft: "30",
          marginRight: "150",
          marginTop: "15",
          marginBottom: "25",
          backgroundColor: "transparent",
        },
        title: {
          text: "",
          style: {
            fontWeight: 400,
            fontSize: 14,
          },
        },
        tooltip: {
          shared: true,
          useHTML: true,
          pointFormat: "{series.name}:<b>{point.y}</b> " + company,
        },
        colors: [
          "#59B9FF",
          "#2CC7BE",
          "#7F8DE1",
          "#4087F2",
          "#F6CA25",
          "#93D053",
          "#F6CA25",
          "#F3768A",
          "#DA4E2E",
          "#875AE6",
        ],
        plotOptions: {
          allowPointSelect: true,
          series: {
            cursor: "pointer",
            events: {
              click: (event) => that.linkagegetHighchartData(event),
            },
            dataLabels: {
              enabled: true,
              crop: false,
              overflow: "none",
              format: "{point.name} : {point.y} " + company, //换行
              softConnector: true,
              style: {
                fontWeight: 400,
                fontSize: 12,
              },
            },
            neckWidth: "40%",
            neckHeight: "35%",
            height: "100%",
            width: "100%",
          },
        },
        legend: {
          enabled: true,
        },
        series: [
          {
            name: this.mapSummaryFieldLabelName,
            data: this.ischangeYesOrNo(arr),
            dataLabels: {
              distance: 30,
              crop: false,
              style: {
                color: this.themeColor === "dark" ? "#B9B8CE" : "#6E7079",
                fontSize: 12,
                textOutline: "none",
              },
            },
          },
        ],
        credits: {
          enabled: false, //不显示logo
        },
      };
      this.$nextTick(() => {
        Highcharts.setOptions({
          lang: {
            thousandsSep: ",",
          },
        });
        Highcharts.chart(this.$refs.funnleChartsRef, this.options);
      });
    },
    // hightcharts点击事件
    linkagegetHighchartData(event) {
      // 主页中销售看板组件中的漏斗，点击需要跳转至销售漏斗菜单
      if (this.dashboardtype === "funnel") {
        this.$emit("clickFunnel");
      }
      let params = {};
      if (event.point.category && event.point.series.name) {
        params = {
          name: event.point.category,
          seriesName: event.point.series.name,
        };
      } else {
        // 无二级分组
        params = {
          name: event.point.name || event.point.category,
        };
      }
      this.$emit("linkagegetData", params);
    },
    getOptionsFunnel() {
      let newDataArr = JSON.parse(JSON.stringify(this.dataArr));
      let myDataArr = JSON.parse(JSON.stringify(this.dataArr));
      // 漏斗图从下往上开始显示，需要把数据倒叙
      myDataArr.reverse(); //悬浮显示数据
      newDataArr.reverse();
      // 漏斗图显示数据，如果有相同数据，漏斗图会显示长方形，不会显示梯形。
      // 按照index排序所有的模块都是梯形
      newDataArr.forEach((list, index) => {
        if (newDataArr.length === 1) {
          list.value = index + 1;
        } else {
          list.value = index;
        }
      });
      let that = this;
      this.options = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "item",
          confine: true, //将此限制打开后tooltip将不再溢出
          formatter: function (params) {
            return ` ${params.data.name}<br />
            ${that.mapSummaryFieldLabelName} :
            <b> ${myDataArr[params.dataIndex].value}</b> `;
          },
        },
        color: [
          "#59B9FF",
          "#2CC7BE",
          "#7F8DE1",
          "#4087F2",
          "#93D053",
          "#F6CA25",
          "#F3768A",
          "#DA4E2E",
          "#875AE6",
        ],
        series: [
          {
            name: "",
            type: "funnel",
            left: "0",
            top: "10%",
            width: "65%",
            height: "80%",
            min: 0,
            max: newDataArr.length,
            minSize: "30%",
            maxSize: "80%",
            sort: "descending",
            data: newDataArr,
            gap: 0.2,
            labelLayout: {
              hideOverlap: true, //标签如果重叠，设置自适应
            },
            label: {
              show: true,
              position: "right",
              formatter: function (val) {
                return (
                  val.data.name + `(` + myDataArr[val.data.value].value + `)`
                );
              },
              color: "#666666",
              fontSize: 12,
            },
            labelLine: {
              length: 25,
              lineStyle: {
                width: 1,
                type: "solid",
              },
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1,
            },
            emphasis: {
              label: {
                fontSize: 12,
              },
              focus: "global",
              blurScope: "coordinateSystem",
            },
          },
        ],
      };
    },
    /**
     * 指针图 8--type:"gauge"
     */
    getOptionsGauge() {
      let that = this;
      this.options = {
        backgroundColor: "transparent",
        tooltip: {
          confine: true, //将此限制打开后tooltip将不再溢出
          formatter: "{b} : <br />{c}",
          trigger: "item",
          textStyle: {
            fontSize: 12,
          },
        },
        series: [
          {
            name: "",
            type: "gauge",
            radius: "100%",
            startAngle: 180, //开始角度
            endAngle: 0, //结束角度
            min: this.min,
            max: this.max,
            splitNumber: 10, //设置间隔区域的显示数量
            center: ["50%", "65%"],
            splitLine: {
              distance: -10,
              length: 10,
              lineStyle: {
                color: "#fff",
                width: 2,
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: 10,
                color: [
                  [(this.mid1 - this.min) / (this.max - this.min), "#FCB95C"],
                  [(this.mid2 - this.min) / (this.max - this.min), "#2CC7BE"],
                  [1, "#4087F2"],
                ],
                shadowBlur: 1.8,
                shadowColor: "rgba(113, 54, 54, 1)",
              },
            },
            axisLabel: {
              show: true,
              color: this.themeColor === "dark" ? "#B9B8CE" : "#6E7079",
              distance: 16,
              fontSize: 11,
              formatter: (value) => that.pushCompany(value,that.max),
            },
            pointer: {
              width: "3%", //指针的宽度
              length: "80%", //指针长度，按照半圆半径的百分比
              itemStyle: {
                color: "auto",
              },
            },
            detail: {
              color: "auto",
              formatter: "11",
              offsetCenter: ["0", "35%"], // x, y，单位px
              textStyle: {
                // 其余属性默认使用全局文本样式
                fontWeight: "bolder",
                fontSize: 18,
              },
            },
            title: {
              //设置仪表盘中间显示文字样式
              offsetCenter: ["0", "15%"],
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                fontSize: 14,
                color: this.themeColor === "dark" ? "#B9B8CE" : "#6E7079",
              },
            },
            data: this.valueDataArr,
          },
        ],
      };
      //只显示数值时，数据添加千分号
      if (this.isshowpercent === "false" && this.isshowvalue === "true") {
        this.options.series[0].detail.formatter = this.changeMicrometerLevel(
          this.valueDataArr[0].value
        );
      } else {
        //（加了两个三元） 判断数据标签中是否需要显示数值及百分比
        this.options.series[0].detail.formatter =
          this.isshowpercent === "true" && this.isshowvalue === "true"
            ? this.changeMicrometerLevel(this.valueDataArr[0].value) +
              "（" +
              ((this.valueDataArr[0].value / this.max) * 100).toFixed(2) +
              "%）"
            : this.isshowpercent === "true" && this.isshowvalue === "false"
            ? "（" +
              ((this.valueDataArr[0].value / this.max) * 100).toFixed(2) +
              "%）"
            : ""; // 显示值：数值千分位与百分比
      }
      /* 
        根据统计值的value值获取对应的名称，需要注意两点：
          1.选择的统计字段是否为记录数；
          2.选择的统计类型是否为整数单位；
      */
      if (!this.mapSummaryWay) return;
      if (this.mapSummaryWay === "count") {
        // 判断选择的是否为记录数，若为记录数，直接显示：'记录数'+单位
        this.options.series[0].data[0].name =
          this.$i18n.t("label.recordamount") +
          (this.unit === "integral"
            ? ""
            : "（" +
              this.$i18n.t("label.custom.unit") +
              "：" +
              units().find((item) => item.value === this.unit).label +
              "）");
      } else {
        // 选择的具体字段来进行统计，则显示：字段+统计类型+单位
        this.options.series[0].data[0].name =
          this.mapSummaryFieldLabelName +
          statisticsTypes().find((item) => item.value === this.mapSummaryWay)
            .label +
          (this.unit === "integral"
            ? ""
            : "（" +
              this.$i18n.t("label.custom.unit") +
              "：" +
              units().find((item) => item.value === this.unit).label +
              "）");
      }
    },
    /**
     * 有第二个分组的折线图 9--type: "line_1"
     */
    getOptionsLine_1() {
      // 取消stack不然折线数据会堆叠
      this.seriesArr.map((item) => {
        item.stack = "";
      });
      let that = this;
      let colorData = this.chooseChartBack(this.groupOneArr, "column_0");
      this.options = {
        tooltip: {
          trigger: "axis",
          confine: true, //将此限制打开后tooltip将不再溢出
        },
        axisPointer: {
          type: "shadow",
        },
        legend: {
          show: true,
          type: "scroll",
          width: "90%",
        },
        stack: "false", //解决数据堆叠
        color: [
          "#7F8DE1",
          "#FCB95C",
          "#4087F2",
          "#2CC7BE",
          "#93D053",
          "#F6CA25",
          "#F3768A",
        ],
        grid: {
          bottom: "20%",
          right: "5%",
          top: "4%",
          left: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          name: this.mapGroupOneFieldLabelName,
          data: this.ischangeYesOrNo(this.groupOneArr),
          axisTick: {
            show: false, //去除X轴上的竖线
          },
          splitArea: {
            interval: "0",
            show: true,
            areaStyle: {
              color: colorData, //这里重点，用于设置颜色的数组，
            },
          },
        },
        yAxis: {
          name: this.mapSummaryFieldLabelName,
          type: "value",
          nameLocation: "middle",
          nameGap: 40,
          axisLabel: {
            formatter: (value) => that.pushCompany(value),
          },
        },
        series: this.seriesArr,
      };
      this.setBarChartsYxias(this.options, this.groupOneArr, "x");
    },
    /**
     * 柱状堆叠图 10--type: "column_duidie"
     */
    getOptionsColumn_duidie() {
      let that = this;
      let colorData = this.chooseChartBack(this.groupOneArr, "column_duidie");
      this.options = {
        color: [
          "#7F8DE1",
          "#4087F2",
          "#FCB95C",
          "#59B9FF",
          "#2CC7BE",
          "#93D053",
          "#F6CA25",
          "#F3768A",
        ],
        backgroundColor: "transparent",
        tooltip: {
          trigger: "item",
          confine: true, //将此限制打开后tooltip将不再溢出
          axisPointer: {
            type: "shadow",
          },
          enterable: true,
          // eslint-disable-next-line no-dupe-keys
          confine: true,
          textStyle: {
            fontSize: 12,
          },
        },
        legend: {
          type: "scroll",
          pageIconColor: this.themeColor === "dark" ? "#aaa" : "#2f4554",
          pageIconInactiveColor:
            this.themeColor === "dark" ? "#2f4554" : "#aaa",
          width: "90%",
          pageTextStyle: {
            color: this.themeColor === "dark" ? "#B9B8CE" : "#6E7079",
          },
        },
        grid: {
          right: "8%",
          left: "10%",
          top: "4%",
          containLabel: true,
        },
        xAxis: {
          name: "",
          nameLocation: "middle",
          type: "category",
          data: this.ischangeYesOrNo(this.groupOneArr),
          axisTick: {
            show: false, //去除X轴上的竖线
          },
          splitArea: {
            interval: "0",
            show: true,
            areaStyle: {
              color: colorData, //这里重点，用于设置颜色的数组，
            },
          },
        },
        yAxis: {
          name: "",
          nameLocation: "middle",
          nameGap: 40,
          type: "value",
          axisLabel: {
            formatter: (value) => that.pushCompany(value),
          },
        },
        series: this.seriesArr,
      };
      this.setBarChartsYxias(
        this.options,
        this.ischangeYesOrNo(this.groupOneArr),
        "x"
      );
    },
    /**
     * 条形堆叠图 11--type: "bar_duidie"
     */
    getOptionsBar_duidie() {
      // eslint-disable-next-line no-unused-vars
      let that = this;
      let colorData = this.chooseChartBack(this.groupOneArr);
      this.options = {
        color: [
          "#4087F2",
          "#59B9FF",
          "#F6CA25",
          "#2CC7BE",
          "#93D053",
          "#A391F2",
          "#F99B4A",
        ],
        tooltip: {
          trigger: "item",
          confine: true, //将此限制打开后tooltip将不再溢出
          enterable: true,
          // eslint-disable-next-line no-dupe-keys
          confine: true,
          textStyle: {
            fontSize: 12,
          },
        },
        grid: {
          top: "2%",
          left: "10%",
          right: "8%",
          bottom: "50",
          containLabel: true,
        },
        legend: {
          type: "scroll",
          pageIconColor: this.themeColor === "dark" ? "#aaa" : "#2f4554",
          pageIconInactiveColor:
            this.themeColor === "dark" ? "#2f4554" : "#aaa",
          bottom: "0",
          width: "90%",
          pageTextStyle: {
            color: this.themeColor === "dark" ? "#B9B8CE" : "#6E7079",
          },
        },
        xAxis: {
          name: "",
          nameLocation: "middle",
          nameGap: 30,
          type: "value",
        },
        yAxis: {
          name: "",
          nameLocation: "middle",
          type: "category",
          inverse: true, // 设置图表默认排序方式
          data: this.ischangeYesOrNo(this.groupOneArr),
          splitArea: {
            show: true,
            areaStyle: {
              color: colorData, //这里重点，用于设置颜色的数组，
            },
          },
        },
        series: this.seriesArr,
      };
    },
    /**
     * 面积堆叠图 12--type: "area"
     */
    getOptionsArea() {
      let that = this;
      this.options = {
        tooltip: {
          trigger: "axis",
          confine: true, //将此限制打开后tooltip将不再溢出
        },
        legend: {
          show: true,
          type: "scroll",
          width: "90%",
        },
        grid: {
          top: "4%",
          right: "5%",
          bottom: "25%",
          containLabel: true,
        },
        color: [
          "#7F8DE1",
          "#FCB95C",
          "#4087F2",
          "#59B9FF",
          "#2CC7BE",
          "#93D053",
          "#F6CA25",
          "#DA4E2E",
          "#F3768A",
        ],
        xAxis: [
          {
            name: this.mapGroupOneFieldLabelName,
            type: "category",
            nameLocation: "middle",
            data: this.ischangeYesOrNo(this.groupOneArr),
          },
        ],
        yAxis: [
          {
            type: "value",
            name: this.mapSummaryFieldLabelName + that.chartShowCompany(),
            nameLocation: "middle",
            nameGap: 40,
            axisLabel: {
              formatter: (value) => that.pushCompany(value),
            },
          },
        ],
        series: this.seriesArr,
      };
      this.setBarChartsYxias(this.options, this.groupOneArr, "x");
    },
    /**
     * 有第二个分组的柱状图 13--type: "column_1"
     */
    getOptionsColumn_1() {
      let columnlabelData = [];
      //x轴的数据，以便计算长度
      this.sourceArr.map((item) => {
        if (item.product) {
          columnlabelData.push(item.product);
        }
      });
      let that = this;
      let colorData = this.chooseChartBack(this.sourceArr, "column_1");
      this.options = {
        color: [
          "#4087F2",
          "#2CC7BE",
          "#F6CA25",
          "#93D053",
          "#F3768A",
          "#A391F2",
          "#FF9A52",
          "#5FBF56",
          "#8374E8",
          "#DA4E2E",
          "#E177ED",
          "#abc4fe",
          "#02C1DD",
          "#6579EB",
          "#9E4BDE",
          "#42B296",
          "#98D44A",
          "#368AC7",
          "#2A63F5",
          "#FF64E0",
        ],
        backgroundColor: "transparent",
        grid: {
          right: "8%",
          top: "4%",
          left: "10%",
          containLabel: false,
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "shadow",
          },
          enterable: true,
          confine: true,
          textStyle: {
            fontSize: 12,
          },
        },
        dataset: {
          dimensions: this.dimensionsArr,
          source: this.ismodifyData(columnlabelData, this.sourceArr),
        },
        legend: {
          type: "scroll",
          pageIconColor: this.themeColor === "dark" ? "#aaa" : "#2f4554",
          pageIconInactiveColor:
            this.themeColor === "dark" ? "#2f4554" : "#aaa",
          bottom: 0,
          // itemHeight:10,//设置图例高度
          width: "90%",
          pageTextStyle: {
            color: this.themeColor === "dark" ? "#B9B8CE" : "#6E7079",
          },
        },
        xAxis: {
          name: "",
          nameLocation: "middle",
          type: "category",
          splitArea: {
            interval: "0",
            show: true,
            areaStyle: {
              color: colorData, //这里重点，用于设置颜色的数组，
            },
          },
        },
        yAxis: {
          name: "",
          nameLocation: "middle",
          nameGap: 42,
          axisLabel: {
            formatter: (value) => that.pushCompany(value),
          },
        },
        series: [],
      };
      // 去除第一个product
      this.dimensionsArr.map((item, index) => {
        if (index > 0) {
          this.options.series.push({ type: "bar" });
        }
      });
      this.setBarChartsYxias(
        this.options,
        this.ischangeYesOrNo(columnlabelData),
        "x"
      );
    },
    /**
     * 有第二个分组的条形图 14--type: "bar_1"
     */
    getOptionsBar_1() {
      let that = this;
      let colorData = this.chooseChartBack(this.groupOneArr);
      this.options = {
        color: [
          "#4087F2",
          "#2CC7BE",
          "#F6CA25",
          "#93D053",
          "#59B9FF",
          "#A391F2",
          "#DA4E2E",
          "#FF9A52",
          "#5FBF56",
          "#8374E8",
          "#E177ED",
          "#abc4fe",
          "#02C1DD",
          "#6579EB",
          "#9E4BDE",
          "#42B296",
          "#98D44A",
          "#368AC7",
          "#2A63F5",
          "#FF64E0",
        ],
        title: {},
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "shadow",
          },
          enterable: true,
          confine: true,
          textStyle: {
            fontSize: 12,
          },
        },
        legend: {
          type: "scroll",
          pageIconColor: this.themeColor === "dark" ? "#aaa" : "#2f4554",
          pageIconInactiveColor:
            this.themeColor === "dark" ? "#2f4554" : "#aaa",
          bottom: "0",
          width: "90%",
          pageTextStyle: {
            color: this.themeColor === "dark" ? "#B9B8CE" : "#6E7079",
          },
          // data: ["2011年", "2012年", "2013年"],
        },
        grid: {
          top: "2%",
          left: "8%",
          right: "8%",
          bottom: "50",
          containLabel: true,
        },
        xAxis: {
          name: "",
          nameLocation: "middle",
          nameGap: 30,
          type: "value",
          boundaryGap: [0, 0.01],
          axisLabel: {
            formatter: (value) => that.pushCompany(value),
          },
        },
        yAxis: {
          name: "",
          nameLocation: "middle",
          type: "category",
          //堆叠图不需要处理this.seriesArr，只处理标签名即可
          data: this.ischangeYesOrNo(this.groupOneArr),
          splitArea: {
            interval: "0",
            show: true,
            areaStyle: {
              color: colorData, //这里重点，用于设置颜色的数组，
            },
          },
        },
        series: this.seriesArr,
      };
    },
    /**
     * USA美国地图
     * @param {usaJson} 地图数据
     */
    getOptionsUsaMap(usaJson) {
      this.mapTypes = "country";
      this.mapDataMax(this.dataArr); //最大值
      if (this.$store.state.userInfoObj.language == "zh") {
        // eslint-disable-next-line no-undef
        echarts.registerMap("USA", usaJson, {
          阿拉斯加: {
            // 把阿拉斯加移到美国主大陆左下方
            left: -131,
            top: 25,
            width: 15,
          },
          夏威夷: {
            left: -110,
            top: 28,
            width: 5,
          },
        });
      }
      if (this.$store.state.userInfoObj.language == "en") {
        // eslint-disable-next-line no-undef
        echarts.registerMap("USA", usaJson, {
          Alaska: {
            // 把阿拉斯加移到美国主大陆左下方
            left: -131,
            top: 25,
            width: 15,
          },
          Hawaii: {
            left: -110,
            top: 28,
            width: 5,
          },
        });
      }
      let avgNumber = this.toNumber(this.mapMax) / 10;
      this.options = {
        title: {
          top: 20, // 定位 值: 'top', 'middle', 'bottom' 也可以是具体的值或者百分比
          left: "center", // 值: 'left', 'center', 'right' 同上
          textStyle: {
            fontSize: 24,
            fontWeight: 600,
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "item",
          confine: true, //将此限制打开后tooltip将不再溢出
          textStyle: { fontSize: 12 }, //提示标签字体颜色
          formatter: (val) => this.worldMapShowFormatter(val),
        },
        visualMap: {
          // left: "1%",
          // bottom: "4%",
          // max: this.mapMax,
          // inRange: {
          //   color: ["#3281ff", "#63bbfb", "#72d9aa", "#90d153", "#ff830d"],
          // },
          // // 文本样式
          // textStyle: {
          //   fontSize: 10,
          //   color: "#666",
          // },
          // hoverLink: true, //鼠标悬浮到visualMap 组件上时,鼠标位置对应的数值 在图表中对应的图形元素,会高亮
          // itemWidth: 16, //图形的宽度，即长条的宽度
          // itemHeight: 100, //图形的高度，即长条的高度
          // realtime: false, // 拖拽时，是否实时更新
          // calculable: false, // 是否显示拖拽用的手柄
          // text: ["", 0], // 文本，默认为数值文本
          min: 0, // minValue 视觉映射组件的最小值
          max: this.mapMax, //  maxValue 视觉映射组件的最大值
          hoverLink: true, //鼠标悬浮到visualMap 组件上时,鼠标位置对应的数值 在图表中对应的图形元素,会高亮
          type: "piecewise", //类型
          splitNumber: 10, //平均分成多少段
          itemSymbol: "rect", //每个图例的数据
          borderWidth: 0, //每个图例的边框
          borderColor: "none",
          textGap: 5, //每个图例距离标签的长度
          itemGap: -3, //每个图例之间的长度
          itemWidth: 15, //每个图例的宽
          itemHeight: 12, //每个图例的高
          left: "0",
          // eslint-disable-next-line no-dupe-keys
          hoverLink: true,
          color: ["#ff830d", "#90d153", "#72d9aa", "#72d9aa", "#3281ff"],
          pieces: [
            {
              gt: 0,
              lte: avgNumber,
              label:
                avgNumber < 1
                  ? avgNumber.toFixed(1)
                  : this.pushCompany(parseInt(avgNumber)),
            },
            { gt: avgNumber, lte: avgNumber * 2, label: " " },
            { gt: avgNumber * 2, lte: avgNumber * 3, label: " " },
            {
              gt: avgNumber * 3,
              lte: avgNumber * 4,
              label:
                avgNumber * 4 < 1
                  ? (avgNumber * 4).toFixed(1)
                  : this.pushCompany(parseInt(avgNumber * 4)),
            },
            { gt: avgNumber * 4, lte: avgNumber * 5, label: " " },
            { gt: avgNumber * 5, lte: avgNumber * 6, label: " " },
            {
              gt: avgNumber * 6,
              lte: avgNumber * 7,
              label:
                avgNumber * 7 < 1
                  ? (avgNumber * 7).toFixed(1)
                  : this.pushCompany(parseInt(avgNumber * 7)),
            },
            { gt: avgNumber * 7, lte: avgNumber * 8, label: " " },
            { gt: avgNumber * 8, lte: avgNumber * 9, label: " " },
            {
              gt: avgNumber * 9,
              lte: avgNumber * 10,
              label:
                avgNumber * 10 < 1
                  ? (avgNumber * 10).toFixed(1)
                  : this.pushCompany(parseInt(avgNumber * 10)),
            },
          ],
        },
        layoutCenter: ["52%", "50%"],
        // layoutSize: "98%",
        series: [
          {
            type: "map",
            // roam: true,缩放事件
            map: "USA",
            emphasis: {
              label: {
                show: true,
              },
            },
            data: this.showMapData(this.dataArr),
            itemStyle: {
              normal: {
                areaColor: "#cecece", //区域背景颜色
                borderWidth: 0.8, //区域边框宽度
                borderColor: "#F6F6F6", //区域边框颜色
              },
              emphasis: {
                areaColor: "#E5ABA6", //鼠标悬停背景色
              },
            },
          },
        ],
      };
    },
    // 英国|新加坡|墨西哥|加拿大|法国|德国|澳大利亚地图
    getMapCase(caseVal, type) {
      let axio = require("axios");
      let origin = window.location.origin;
      // eslint-disable-next-line no-undef
      let Language = "";
      if (this.$store.state.userInfoObj.language == "zh") {
        Language = "/cloudcc-json/" + caseVal + "Chinese.json";
      } else {
        Language = "/cloudcc-json/" + caseVal + "English.json";
      }
      axio.get(origin + Language).then((res) => {
        this.$emit("maploading");
        this.getOptionsOther(type, res.data);
      });
    },
    //英国|新加坡|墨西哥|加拿大|法国|德国|澳大利亚地图
    getOptionsOther(type, ukJson) {
      this.mapTypes = "country";
      this.mapDataMax(this.dataArr); //最大值
      // eslint-disable-next-line no-undef
      echarts.registerMap(type, ukJson);
      let avgNumber = this.toNumber(this.mapMax) / 10;
      this.options = {
        title: {
          top: 20, // 定位 值: 'top', 'middle', 'bottom' 也可以是具体的值或者百分比
          left: "center", // 值: 'left', 'center', 'right' 同上
          textStyle: {
            fontSize: 24,
            fontWeight: 600,
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "item",
          confine: true, //将此限制打开后tooltip将不再溢出
          textStyle: { fontSize: 12 }, //提示标签字体颜色
          formatter: (val) => this.worldMapShowFormatter(val),
        },
        visualMap: {
          // realtime: false, // 拖拽时，是否实时更新
          // calculable: false, // 是否显示拖拽用的手柄
          min: 0, // minValue 视觉映射组件的最小值
          max: this.mapMax, //  maxValue 视觉映射组件的最大值
          hoverLink: true, //鼠标悬浮到visualMap 组件上时,鼠标位置对应的数值 在图表中对应的图形元素,会高亮
          type: "piecewise", //类型
          splitNumber: 10, //平均分成多少段
          itemSymbol: "rect", //每个图例的数据
          borderWidth: 0, //每个图例的边框
          borderColor: "none",
          textGap: 5, //每个图例距离标签的长度
          itemGap: -3, //每个图例之间的长度
          itemWidth: 15, //每个图例的宽
          itemHeight: 12, //每个图例的高
          left: "0",
          // eslint-disable-next-line no-dupe-keys
          hoverLink: true,
          color: ["#ff830d", "#90d153", "#72d9aa", "#72d9aa", "#3281ff"],
          pieces: [
            {
              gt: 0,
              lte: avgNumber,
              label:
                avgNumber < 1
                  ? avgNumber.toFixed(1)
                  : this.pushCompany(parseInt(avgNumber)),
            },
            { gt: avgNumber, lte: avgNumber * 2, label: " " },
            { gt: avgNumber * 2, lte: avgNumber * 3, label: " " },
            {
              gt: avgNumber * 3,
              lte: avgNumber * 4,
              label:
                avgNumber * 4 < 1
                  ? (avgNumber * 4).toFixed(1)
                  : this.pushCompany(parseInt(avgNumber * 4)),
            },
            { gt: avgNumber * 4, lte: avgNumber * 5, label: " " },
            { gt: avgNumber * 5, lte: avgNumber * 6, label: " " },
            {
              gt: avgNumber * 6,
              lte: avgNumber * 7,
              label:
                avgNumber * 7 < 1
                  ? (avgNumber * 7).toFixed(1)
                  : this.pushCompany(parseInt(avgNumber * 7)),
            },
            { gt: avgNumber * 7, lte: avgNumber * 8, label: " " },
            { gt: avgNumber * 8, lte: avgNumber * 9, label: " " },
            {
              gt: avgNumber * 9,
              lte: avgNumber * 10,
              label:
                avgNumber * 10 < 1
                  ? (avgNumber * 10).toFixed(1)
                  : this.pushCompany(parseInt(avgNumber * 10)),
            },
          ],
        },
        layoutCenter: ["52%", "50%"],
        layoutSize: "92%",
        series: [
          {
            type: "map",
            // roam: true,缩放事件
            map: type,
            emphasis: {
              label: {
                show: true,
              },
            },
            data: this.showMapData(this.dataArr),
            itemStyle: {
              normal: {
                areaColor: "#cecece", //区域背景颜色
                borderWidth: 0.8, //区域边框宽度
                borderColor: "#F6F6F6", //区域边框颜色
              },
              emphasis: {
                areaColor: "#E5ABA6", //鼠标悬停背景色
              },
            },
          },
        ],
      };
    },
    /**
     * 没有第二个分组的世界地图 18--type: "worldMap"
     * @param {worldJson} 地图数据
     */
    getOptionsWordMap(worldJson) {
      this.mapTypes = "country";
      this.mapDataMax(this.dataArr); //最大值
      // eslint-disable-next-line no-undef
      echarts.registerMap("world", worldJson);
      let avgNumber = this.toNumber(this.mapMax) / 10;
      this.options = {
        // 提示框组件
        tooltip: {
          confine: true, //将此限制打开后tooltip将不再溢出
          top: 20, // 定位 值: 'top', 'middle', 'bottom' 也可以是具体的值或者百分比
          left: "center", // 值: 'left', 'center', 'right' 同上
          trigger: "item", // 触发类型, 数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用
          // 提示框浮层内容格式器，支持字符串模板和回调函数两种形式  ` <br /> ${this.mapSummaryFieldLabelName}:`
          // 使用函数模板  传入的数据值 -> value: number | Array
          formatter: (val) => this.worldMapShowFormatter(val),
          textStyle: { fontSize: 12 }, //提示标签字体颜色
        },
        // 视觉映射组件
        visualMap: {
          // left: "1%",
          // bottom: "4%",
          // // 文本样式
          // textStyle: {
          //   fontSize: 10,
          //   color: "#666",
          // },
          // realtime: false, // 拖拽时，是否实时更新
          // calculable: false, // 是否显示拖拽用的手柄
          min: 0, // minValue 视觉映射组件的最小值
          max: this.mapMax, //  maxValue 视觉映射组件的最大值
          hoverLink: true, //鼠标悬浮到visualMap 组件上时,鼠标位置对应的数值 在图表中对应的图形元素,会高亮
          type: "piecewise", //类型
          splitNumber: 10, //平均分成多少段
          itemSymbol: "rect", //每个图例的数据
          borderWidth: 0, //每个图例的边框
          borderColor: "none",
          textGap: 5, //每个图例距离标签的长度
          itemGap: -3, //每个图例之间的长度
          itemWidth: 15, //每个图例的宽
          itemHeight: 12, //每个图例的高
          left: "0",
          // eslint-disable-next-line no-dupe-keys
          hoverLink: true,
          color: ["#ff830d", "#90d153", "#72d9aa", "#72d9aa", "#3281ff"],
          pieces: [
            {
              gt: 0,
              lte: avgNumber,
              label:
                avgNumber < 1
                  ? avgNumber.toFixed(1)
                  : this.pushCompany(parseInt(avgNumber)),
            },
            { gt: avgNumber, lte: avgNumber * 2, label: " " },
            { gt: avgNumber * 2, lte: avgNumber * 3, label: " " },
            {
              gt: avgNumber * 3,
              lte: avgNumber * 4,
              label:
                avgNumber * 4 < 1
                  ? (avgNumber * 4).toFixed(1)
                  : this.pushCompany(parseInt(avgNumber * 4)),
            },
            { gt: avgNumber * 4, lte: avgNumber * 5, label: " " },
            { gt: avgNumber * 5, lte: avgNumber * 6, label: " " },
            {
              gt: avgNumber * 6,
              lte: avgNumber * 7,
              label:
                avgNumber * 7 < 1
                  ? (avgNumber * 7).toFixed(1)
                  : this.pushCompany(parseInt(avgNumber * 7)),
            },
            { gt: avgNumber * 7, lte: avgNumber * 8, label: " " },
            { gt: avgNumber * 8, lte: avgNumber * 9, label: " " },
            {
              gt: avgNumber * 9,
              lte: avgNumber * 10,
              label:
                avgNumber * 10 < 1
                  ? (avgNumber * 10).toFixed(1)
                  : this.pushCompany(parseInt(avgNumber * 10)),
            },
          ],
        },
        layoutCenter: ["52%", "50%"],
        // layoutSize: "100%",
        series: [
          {
            type: "map",
            mapType: "world",
            data: this.showMapData(this.dataArr), //绑定数据
            symbolSize: 14,
            // center: ['50%', '10%'], // 调整地图位置
            emphasis: {
              label: {
                show: true,
              },
            },
            itemStyle: {
              normal: {
                areaColor: "#cecece", //区域背景颜色
                borderWidth: 0.8, //区域边框宽度
                borderColor: "#F6F6F6", //区域边框颜色
              },
              emphasis: {
                areaColor: "#E5ABA6", //鼠标悬停背景色
              },
            },
          },
        ],
      };
    },
    /**
     * 没有第二个分组的热力图/地图 17--type: "heatMap"
     * @param {chinaMap} 地图数据
     */
    getOptionsHeatMap(chinaMap) {
      // eslint-disable-next-line no-undef
      echarts.registerMap("china", chinaMap, {});
      let dataArr = JSON.parse(JSON.stringify(this.dataArr));
      // 省
      if (this.mapGroupOneField.address == "province") {
        this.mapTypes = "map";
        dataArr.map((item) => {
          item.names = item.name;
          item.name = this.dealName(item.name); // 对name处理，控制地区是否显示
        });
      } else if (this.mapGroupOneField.address == "city") {
        // 市
        this.mapTypes = "scatter";
        dataArr.map((item) => {
          item.names = item.name;
          // 判断中英文环境对数据进行不同的处理
          if (this.$store.state.userInfoObj.language == "zh") {
            // 判断最后一个字是不是"市",不是的话,拼接一个"市"字
            let mapNameLast = item.name.substr(item.name.length - 1, 1);
            if (mapNameLast != "市") {
              item.name = item.name + "市";
            }
          } else {
            if (
              item.name != "Lishi" &&
              item.name != "Huangshi" &&
              item.name != "Shashi" &&
              item.name.substring(item.name.length - 3) == "shi"
            ) {
              item.name = item.name.replace(/shi/, "");
            }
            // 英文判断城市中是否包含city，不包含的话拼接City
            if (item.name.toLowerCase().indexOf("city") == -1) {
              item.name = item.name + " City";
            }
            // 字符串首字母大写
            if (escape(item.name).indexOf("%u") < 0) {
              item.name =
                item.name.slice(0, 1).toUpperCase() + item.name.slice(1);
            }
          }
          // 这个判断是为了全屏加的
          if (typeof item.value === "number") {
            /*
             根据item.name值去CITYSLNGLAT配置文件中匹配经纬度
             将经度纬度以及value值组成新的item.value来显示地图'市'的位置
            */
            let cityLanguage = [];
            if (this.$store.state.userInfoObj.language == "zh") {
              cityLanguage = cityChinese;
            } else {
              cityLanguage = cityEnglish;
            }
            cityLanguage.map((item1) => {
              if (item.name == item1.name) {
                item.value = (
                  item1.lng +
                  "," +
                  item1.lat +
                  "," +
                  item.value
                ).split(",");
              }
            });
          }
          // 将未匹配到的直辖市添加一个默认的经纬度0,0，这样在视觉映射时格式一致设置的维度才会正常显示
          if (typeof item.value === "number") {
            item.value = ("0" + "," + "0" + "," + item.value).split(",");
          }
        });
        dataArr = this.mapDataQu(dataArr); // 去重
      }
      dataArr = this.showMapData(dataArr);//省和城市数据大于0时在地图上显示
      this.mapDataMax(dataArr); //最大值
      /*mapData:图表所需数据;
        mapType:地图模式(map即按省份显示数据)或散点图模式(scatter即按城市显示数据)
        minValue:视觉映射组件最小值;
        maxValue:视觉映射最大值 */
      let avgNumber = this.toNumber(this.mapMax) / 10;
      this.options = {
        //提示标签
        tooltip: {
          trigger: "item",
          confine: true, //将此限制打开后tooltip将不再溢出
          textStyle: { fontSize: 12 }, //提示标签字体颜色
          formatter: (params) => this.heatMapShowFormatter(params),
        },
        //视觉映射组件
        visualMap: {
          min: 0, // minValue 视觉映射组件的最小值
          max: this.mapMax, //  maxValue 视觉映射组件的最大值
          // calculable: false, //是否显示拖拽用的手柄（手柄能拖拽调整选中范围）
          // inRange: {
          //   // color: ["#52a6ef", "#6dd5b2", "#fbc165", "#f57167", "#b11813"],
          //   color: ["#3281ff", "#63bbfb", "#72d9aa", "#90d153", "#ff830d"],
          // }, //定义 在选中范围中 的视觉元素
          // outOfRange: {
          //   //定义 在选中范围外 的视觉元素。
          //   // color: [],
          //   // symbolSize: [30, 100],
          // },
          // dimension: this.mapTypes === "map" ? 0 : 2, //指定用数据的『哪个维度』，映射到视觉元素上。『数据』即 series.data。 可以把 series.data 理解成一个二维数组,其中每个列是一个维度,默认取 data 中最后一个维度
          hoverLink: true, //鼠标悬浮到visualMap 组件上时,鼠标位置对应的数值 在图表中对应的图形元素,会高亮
          type: "piecewise", //类型
          splitNumber: 10, //平均分成多少段
          itemSymbol: "rect", //每个图例的数据
          borderWidth: 0, //每个图例的边框
          borderColor: "none",
          textGap: 5, //每个图例距离标签的长度
          itemGap: -3, //每个图例之间的长度
          itemWidth: 15, //每个图例的宽
          itemHeight: 12, //每个图例的高
          left: "0",
          // eslint-disable-next-line no-dupe-keys
          hoverLink: true,
          color: ["#ff830d", "#90d153", "#72d9aa", "#72d9aa", "#3281ff"],
          pieces: [
            {
              gt: 0,
              lte: avgNumber,
              label:
                avgNumber < 1
                  ? avgNumber.toFixed(1)
                  : this.pushCompany(parseInt(avgNumber)),
            },
            { gt: avgNumber, lte: avgNumber * 2, label: " " },
            { gt: avgNumber * 2, lte: avgNumber * 3, label: " " },
            {
              gt: avgNumber * 3,
              lte: avgNumber * 4,
              label:
                avgNumber * 4 < 1
                  ? (avgNumber * 4).toFixed(1)
                  : this.pushCompany(parseInt(avgNumber * 4)),
            },
            { gt: avgNumber * 4, lte: avgNumber * 5, label: " " },
            { gt: avgNumber * 5, lte: avgNumber * 6, label: " " },
            {
              gt: avgNumber * 6,
              lte: avgNumber * 7,
              label:
                avgNumber * 7 < 1
                  ? (avgNumber * 7).toFixed(1)
                  : this.pushCompany(parseInt(avgNumber * 7)),
            },
            { gt: avgNumber * 7, lte: avgNumber * 8, label: " " },
            { gt: avgNumber * 8, lte: avgNumber * 9, label: " " },
            {
              gt: avgNumber * 9,
              lte: avgNumber * 10,
              label:
                avgNumber * 10 < 1
                  ? (avgNumber * 10).toFixed(1)
                  : this.pushCompany(parseInt(avgNumber * 10)),
            },
          ],
        },
        layoutCenter: ["52%", "50%"],
        layoutSize: "95%",
        // 市
        geo: {
          map: "china",
          label: {
            emphasis: {
              show: true, //城市模式下，鼠标移上去显示省份名称
            },
          },
          itemStyle: {
            normal: {
              areaColor: "#cecece", //区域背景颜色
              borderColor: "#F6F6F6", //区域边框颜色
              borderWidth: 0.8, //区域边框宽度
            },
            emphasis: {
              areaColor: "#E5ABA6", //鼠标悬停背景色
            },
          },
        },
        series: [
          {
            name: "map",
            type: this.mapTypes, //mapTypes可选"scatter"或"map",即选择散点图模式或地图模式
            mapType: "china",
            coordinateSystem: "geo",
            symbolSize: 14,
            label: {
              normal: {
                formatter: "", //"{b}:{c}"城市模式下在地图上显示该城市名称
                textStyle: {
                  color: "#000",
                },
                show: false,
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {
              normal: {
                areaColor: "#cecece", //区域背景颜色
                borderWidth: 0.8, //区域边框宽度
                borderColor: "#F6F6F6", //区域边框颜色
              },
              emphasis: {
                areaColor: "#E5ABA6", //鼠标悬停背景色
              },
            },

            data: dataArr,
          },
        ],
      };
    },
    /**
     * 矩阵树形图 18--type: "matrixTree"
     */
    getOptionsMatrixTree() {
      let aName = []; // name
      let bValue = []; // value
      let cColorValue = []; //colorValue
      // 列表视图
      let listGroup = [];
      let listData = [];
      // 从列表视图生成的数据源,根据objectLable判断数据来源
      if (this.objectLable) {
        // 统计值字段
        this.listChartData.map((item) => {
          listData.push({ name: item.mapSummaryField.labelName });
        });
        // 获取一级分组的数据，每条数据都会返回一级分组数据，默认取第一条根据字段拼接一级分组数据
        let labelName =
          this.listChartData[0].mapGroupOneField.schemefield_name_alias;
        this.listChartData[0].listChartData.map((item) => {
          listGroup.push(item[labelName]);
        });
        // 统计维度,每条数据返回，一级分组数据和统计值数据，根据统计值字段拼接统计值数据
        listData.map((item, index) => {
          this.listChartData.map(() => {
            let apiName =
              this.listChartData[index].mapSummaryField.schemefield_name_alias;
            let value = [];
            this.listChartData[index].listChartData.map((item2) => {
              value.push(item2[apiName]);
              this.$set(item, "data", value);
            });
          });
        });
        aName = listGroup;
        bValue = listData[0].data;
        cColorValue = listData[1].data;
      } else {
        aName = this.listChartData[0].listGroup; // name
        bValue = this.listChartData[0].listData[0].data; // value
        cColorValue = this.listChartData[0].listData[1].data; //colorValue
      }

      // 判断图表显示位置：报表，仪表板
      // 鼠标悬浮显示样式：
      // 2021-07-01   (分组类型)
      // 金额：666666 (颜色深度)
      // 记录数：22   (矩阵大小)
      let colorName = "";
      let sizeName = "";
      // 从报表生成
      if (this.reportName) {
        sizeName = this.listChartData[0].listData[0].name;
        colorName = this.listChartData[0].listData[1].name;
      } else {
        sizeName = this.mapGroupOneFieldLabelName;
        colorName = this.mapSummaryFieldLabelName;
      }
      // 从列表视图生成
      if (this.objectLable) {
        sizeName = this.listChartData[0].mapSummaryField.labelName;
        colorName = this.listChartData[1].mapSummaryField.labelName;
      }
      let dataArr = []; //格子大小数据
      let colorValue = []; //颜色深度数据
      let maxValue = 0; //最大值
      aName.forEach((item, index) => {
        if (maxValue < bValue[index]) {
          maxValue = Math.ceil(bValue[index]);
        }
        dataArr.push({
          name: aName[index],
          value: Number(bValue[index]),
        });
        colorValue.push(Number(cColorValue[index]));
      });
      let that = this;
      this.options = {
        color: ["#7F8DE1", "#ECEFFF"],
        tooltip: {
          confine: true, //将此限制打开后tooltip将不再溢出
          formatter: function (params) {
            return `${params.name}<br /> 
            ${colorName} 
            : <b>${
              colorValue[params.dataIndex - 1]
                ? that.changeMicrometerLevel(
                    Number(colorValue[params.dataIndex - 1])
                  )
                : 0
            }</b><br /> 
            ${sizeName} 
            : <b>${that.changeMicrometerLevel(params.value)}</b><br />`;
          },
        },
        // 视觉映射组件
        // visualMap: {
        //   max: maxValue,
        //   min: 0, // minValue 视觉映射组件的最小值
        //   type: "continuous", // continuous 类型为连续型  piecewise 类型为分段型
        //   inRange: {
        //     color: ["#ECEFFF", "#7F8DE1"],
        //   }, //定义 在选中范围中 的视觉元素
        //   hoverLink: true, //鼠标悬浮到visualMap 组件上时,鼠标位置对应的数值 在图表中对应的图形元素,会高亮
        //   realtime: false, // 拖拽时，是否实时更新
        //   calculable: false, // 是否显示拖拽用的手柄
        //   itemWidth: 16, //图形的宽度，即长条的宽度
        //   itemHeight: 100, //图形的高度，即长条的高度
        //   left: "2%",
        //   bottom: "4%",
        //   precision: 0, //显示小数位
        //   orient: "vertical", // visualMap 组件，水平（'horizontal'）或者竖直（'vertical'）。
        //   text: [maxValue, 0], // 文本，默认为数值文本
        // },
        // colorMappingBy颜色深度和视觉映射组件互相影响
        series: [
          {
            type: "treemap",
            roam: false, //鼠标滚动是否缩放
            nodeClick: false, //点击节点不生效
            width: "90%",
            height: "90%",
            top: "2%",
            colorMappingBy: colorValue, //将节点的值（即 series-treemap.data.value）映射到颜色列表中。这样得到的颜色，反应了节点值的大小。
            label: {
              color: "#333333",
              fontSize: "12",
              borderColor: "#333",
              borderType: "solid",
            },
            itemStyle: {
              gapWidth: 1, //矩形边框线宽。为 0 时无边框。而矩形的内部子矩形（子节点）的间隔距离是由 gapWidth 指定的。
              borderColor: "#fff",
            },
            breadcrumb: {
              //是否显示图例黑色图例
              show: false,
            },
            data: dataArr,
          },
        ],
      };
    },
    /**
     * 雷达图 19--type: "radarMap"
     */
    getOptionsRadarMap() {
      let indicator = [];
      let data = [];
      // 从列表视图生成的数据源,根据objectLable判断数据来源
      if (this.objectLable) {
        // 组装indicator:一级分组字段
        this.listChartData.map((item) => {
          indicator.push({ name: item.mapSummaryField.labelName });
        });
        // 获取分组对应字段
        let labelName =
          this.listChartData[0].mapGroupOneField.schemefield_name_alias;
        this.listChartData[0].listChartData.map((item) => {
          data.push({ name: item[labelName] });
        });
        // 统计维度：每个类型的统计值存放在同一数组，从每组数据获取相同下标数据组成一条完整数据。
        data.map((item, index) => {
          let value = [];
          this.listChartData.map((item1) => {
            let apiName = item1.mapSummaryField.schemefield_name_alias;
            value.push(item1.listChartData[index][apiName]);
          });
          this.$set(item, "value", value);
        });
      } else {
        // 从报表生成的数据源
        // 组装indicator
        this.listChartData[0].listData.map((item) => {
          indicator.push({ name: item.name });
        });
        // 组装data
        this.listChartData[0].listGroup.map((item, index) => {
          let value = [];
          this.listChartData[0].listData.map((items) => {
            value.push(items.data[index]);
          });
          data.push({
            name: item,
            value: value,
          });
        });
      }
      let radarName = data.map((item) => {
        return item.name;
      });
      this.options = {
        title: {
          text: "",
        },
        colors: ["#DA4E2E", "#93D053", "#4087F2", "#F99B4A", "#7F8DE1"],
        tooltip: {
          trigger: "axis",
          confine: true, //将此限制打开后tooltip将不再溢出
        },
        legend: {
          radarName,
          type: "scroll",
          symbolHeight: 10,
          symbolWidth: 10,
          symbolRadius: 0,
          top: "86%",
          width: "90%",
        },
        radar: {
          indicator: indicator,
          center: ["50%", "42%"], //图表位置：X,Y轴
          radius: "50%", //图表的大小
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: ["#ccc"],
              // 图表背景网格线的颜色
            },
          },
        },
        series: [
          {
            // name: "",
            type: "radar",
            tooltip: {
              trigger: "item",
            },
            data: data,
          },
        ],
      };
    },
    /**
     * 气泡图 20--type: "bubble"
     */
    getOptionsBubble() {
      let seriesBubble = []; //x,y轴定位点的数据
      let listGroup = []; //name数据
      let listData = [];
      let sizeName = ""; //气泡大小的字段
      // // 从列表视图生成的数据源,根据objectLable判断数据来源
      if (this.objectLable) {
        let labelName =
          this.listChartData[0].mapGroupOneField.schemefield_name_alias;
        this.listChartData[0].listChartData.map((item) => {
          listData.push([item[labelName]]);
          listGroup.push(item[labelName]);
        });
        listData.map((item, index) => {
          let value = [];
          this.listChartData.map((item1) => {
            let apiName = item1.mapSummaryField.schemefield_name_alias;
            value.push(item1.listChartData[index][apiName]);
          });
          value.push(item[0]);
          seriesBubble.push([value]);
          sizeName = this.listChartData[2].mapSummaryField.name;
        });
      } else {
        listGroup = this.listChartData[0].listGroup;
        listGroup.map((item) => {
          listData.push([item]);
        });
        let value = [];
        // 组装series
        this.listChartData[1].data.map((item, index) => {
          value = item.map(Number);
          value.push(listGroup[index]);

          seriesBubble.push([value]);
        });
        sizeName = this.listChartData[0].listData[2].name;
      }
      let that = this;
      this.options = {
        color: [
          "#7F8DE1",
          "#F99B4A",
          "#4087F2",
          "#59B9FF",
          "#2CC7BE",
          "#93D053",
          "#F6CA25",
          "#DA4E2E",
        ],
        legend: {
          show: true,
          type: "scroll",
          orient: "horizontal",
          bottom: 10,
          width: "90%",
        },
        tooltip: {
          position: "top",
          confine: true, //将此限制打开后tooltip将不再溢出
          formatter: function (params) {
            return `
             ${listData[params.componentIndex]}</br>
            ${sizeName}： <b> ${params.data[2] ? params.data[2] : "0"}</b> `;
          },
        },
        grid: {
          left: "8%",
          right: "18%",
          top: "7%",
          bottom: "20%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          nameLocation: "end",
          axisLabel: {
            formatter: (value) => that.pushCompany(value),
          },
        },
        yAxis: {
          // name: this.listChartData[1].mapSummaryField.labelName,
          type: "value",
          nameLocation: "end",
          axisLabel: {
            formatter: (value) => that.pushCompany(value),
          },
        },
        colorBy: "data",
        series: [
          {
            type: "scatter",
          },
        ],
      };
      listData.map((item, index) => {
        this.options.series[index] = {
          data: seriesBubble[index],
          name: item[0],
          type: "scatter",
          itemStyle: {
            //未选种样式
            opacity: 0.4,
            borderWidth: 1,
            borderType: "solid",
          },
          emphasis: {
            //选中高亮样式
            scale: true,
            focus: "global",
            borderWidth: 2,
            borderType: "solid",
            blurScope: "coordinateSystem",
          },
          symbolSize: function () {
            return that.setSymbolSize(seriesBubble[index][0][2]);
          },
        };
      });
    },
    // 根据数值设置气泡的大小
    setSymbolSize(value) {
      if (value < 100) {
        return 13;
      } else if (value < 1000 && value >= 100) {
        return 16;
      } else if (value < 10000 && value >= 1000) {
        return 20;
      } else if (value < 100000 && value >= 10000) {
        return 24;
      } else if (value < 1000000 && value >= 100000) {
        return 26;
      } else if (value < 10000000000000 && value >= 1000000) {
        return 28;
      } else {
        return 12;
      }
    },
    /**
     * 双轴图 21--type: "biaxialFigure"
     */
    getOptionsBiaxialFigure(value) {
      this.summaryway = []; //统计类型
      let xcon = []; //统计值
      let mapGroupOneData = []; //一级分组数据
      this.listGroupData = []; //清空数据
      if (this.showData.chartInfo && this.showData.chartInfo.summaryway) {
        this.summaryway = this.showData.chartInfo.summaryway.split(",");
        xcon = this.showData.chartInfo.xcon.split(",");
      } else if (
        this.showData.reportInfo &&
        this.showData.reportInfo.summaryway
      ) {
        this.summaryway = this.showData.reportInfo.summaryway.split(",");
        xcon = this.showData.reportInfo.xcon.split(",");
      }
      // 记录数返回在mapCountField的情况
      // 1、仪表板：数据源从报表生成
      // 2、仪表板：数据源从列表视图生成后，展示仪表板时(getChartDataById)
      // 3、在报表中显示图表
      // 判断统计类型为count，且没有mapCountField字段时，count赋值sum
      if (this.summaryway[0] === "count") {
        this.summaryway[0] = "sum";
      }
      if (this.summaryway[1] === "count") {
        this.summaryway[1] = "sum";
      }
      this.summaryway.map((item, index) => {
        if (this.summaryway[0] === this.summaryway[1]) {
          this.biaxialFigureData(item, index, xcon[index]);
        } else {
          this.biaxialFigureData(item, 0, xcon[index]);
        }
      });
      let ycon = this.showData.mapGroupOneField.schemefield_name_alias; //一级分组字段
      mapGroupOneData = this.listChartData[0][ycon];
      // 获取Y轴两侧的最大值，比较两个值，根据是否勾选刻度一致按钮来给Y轴两侧的最大值赋值
      let rightMaxValue = Math.max.apply(1, this.listGroupData[1].value);
      let leftMaxValue = Math.max.apply(1, this.listGroupData[0].value);
      let maxValue = 0;
      if (rightMaxValue > leftMaxValue) {
        maxValue = rightMaxValue;
      } else {
        maxValue = leftMaxValue;
      }
      let colorData = this.chooseChartBack(
        this.listGroupData[0].value,
        "biaxialFigure"
      );
      let that = this;
      this.options = {
        tooltip: {
          trigger: "axis",
          confine: true, //将此限制打开后tooltip将不再溢出
        },
        title: {
          text: "",
        },
        grid: {
          top: "11%",
          right: "6%",
          containLabel: true,
        },
        legend: {
          show: true,
          type: "scroll",
          width: "90%",
          data: [this.listGroupData[0].name, this.listGroupData[1].name],
        },
        xAxis: [
          {
            type: "category",
            data: this.ischangeYesOrNo(mapGroupOneData),
            nameLocation: "middle",
            axisPointer: {
              type: "shadow",
            },
            splitArea: {
              interval: "0",
              show: true,
              areaStyle: {
                color: colorData, //这里重点，用于设置颜色的数组，
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: this.listGroupData[0].name,
            min: 0,
            axisLabel: {
              formatter: (value) => that.pushCompany(value),
            },
          },
          {
            type: "value",
            name: this.listGroupData[1].name,
            min: 0,
            nameTextStyle: {
              align: "right",
            },
            axisLabel: {
              formatter: (value) => that.pushCompany(value),
            },
          },
        ],
        series: [
          {
            name: this.listGroupData[0].name,
            type: "bar",
            data: this.listGroupData[0].value,
          },
          {
            name: this.listGroupData[1].name,
            type: "line",
            yAxisIndex: 1,
            data: this.listGroupData[1].value,
            itemStyle: {
              normal: {
                color: "#FCB95C", //圈圈的颜色
                lineStyle: {
                  color: "#FCB95C", //线的颜色
                },
              },
            },
          },
        ],
      };
      // 判断是编辑框动态显示图表还是在仪表板情况下显示图表
      // 动态根据BiaxialFigureCheck判断
      // 仪表板根据接口返回值判断
      if (value == "dynamic") {
        if (this.BiaxialFigureCheck == true) {
          this.options.yAxis[0].max = this.toNumber(maxValue);
          this.options.yAxis[1].max = this.toNumber(maxValue);
        }
      } else {
        if (this.showData.chartInfo.isagreement == "true") {
          this.options.yAxis[0].max = this.toNumber(maxValue);
          this.options.yAxis[1].max = this.toNumber(maxValue);
        }
      }
      this.setBarChartsYxias(this.options, mapGroupOneData, "x");
    },
    /**
     * 目标图 22--type: "target"
     */
    getOptionsTarget() {
      let that = this;
      let companyUnit = this.pieChartsUnit(); //获取单位
      // 目标值：多语言
      let targetValue = this.$i18n.t(
        "front-lightning-module-v1-dashborad-target-value"
      );
      let listtarget=0;//目标值
      let listvalue=0;//实际数值
      let maxNumber =0;//目标值和实际数值中的最大值
      if(this.listChartData.length>0){
        maxNumber =
        Number(this.listChartData[0].target) < this.listChartData[0].value
          ? this.listChartData[0].value
          : Number(this.listChartData[0].target);
          listtarget=Number(this.listChartData[0].target)
          listvalue=Number(this.listChartData[0].value)
      }else if(this.listChartData.target){
        maxNumber =
        Number(this.listChartData.target) < this.listChartData.value
          ? this.listChartData.value
          : Number(this.listChartData.target);
          listtarget=Number(this.listChartData.target)
          listvalue=Number(this.listChartData.value)
      }
      this.options = {
        tooltip: {
          trigger: "axis",
          confine: true, //将此限制打开后tooltip将不再溢出
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            return `${that.mapSummaryFieldLabelName} :
            <b> ${that.changeMicrometerLevel(
              params[0].value
            )}</b> ${companyUnit} `;
          },
        },
        xAxis: {
          name: that.chartShowCompany(),
          nameGap: 30,
          nameLocation: "middle",
          // 判断目标值和实际值的大小，取其中较大的值；
          max: maxNumber, // 重点: 要比400大
          // min: 0,
          axisLabel: {
            formatter: (value) => that.pushCompany(value),
          },
        },
        grid: {
          containLabel: true,
          right: "8%",
          left: "1%",
          top: "8%",
          bottom: "15%",
        },
        yAxis: {
          data: [this.mapSummaryFieldLabelName],
        },
        series: [
          {
            name: this.mapSummaryFieldLabelName,
            type: "bar",
            barMaxWidth: "45%",
            itemStyle: {
              normal: {
                color: "#808CE1", // bar颜色
              },
            },
            data: [listvalue],
            markLine: {
              data: [
                {
                  name: targetValue,
                  xAxis: listtarget, // 这里是400
                },
              ],
              lineStyle: {
                type: "solid",
                color: "#F99B4A",
              },
              silent: true,
              symbolSize: 1,
              label: {
                show: true,
                position: "end", //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                formatter: function () {
                  return targetValue;
                },
                rotate: 0,
                distance: 0,
              },
            },
          },
        ],
      };
      // 如果实际值大于目标值显示已实现图例
      if ( listtarget < listvalue) {
        this.options.color = ["#808ce1"];
      }
      this.setBarChartsYxias(
        this.options,
        [this.mapSummaryFieldLabelName],
        "y"
      );
    },
    /**
     * 同环比双轴图 24--type: "biaxial"
     */
    getOptionsBiaxial() {
      // 需要截取掉'%'
      // 增长率
      let grow = this.$i18n.t("c12");
      let that = this;
      let dataYear = [];
      let dataMonth = [];
      let growthrateyData = []; //存放Y轴数据
      // 判断日期长度/2
      let dateLength = this.listChartData[0].timeGroupMap.length / 2;
      for (let i = 0; i < dateLength; i++) {
        dataYear.push("-");
        dataMonth.push("-");
      }
      this.listChartData[0].yearGrowthMap.data.map((item) => {
        item = item.substring(0, item.length - 1);
        dataYear.push(item.substring(0, item.length));
      });
      //环比增长
      this.listChartData[0].monthGrowthMap.data.map((item) => {
        item = item.substring(0, item.length - 1);
        dataMonth.push(item.substring(0, item.length));
        growthrateyData.push(Number(item.substring(0, item.length)));
      });
      this.options = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          confine: true, //将此限制打开后tooltip将不再溢出
          // axisPointer: {//显示X，Y轴数据和提示框重复
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        grid: {
          top: "12%",
          left: "5%",
          right: "6%",
          containLabel: true,
        },
        legend: {
          show: true,
          type: "scroll",
          width: "90%",
          data: [
            this.listChartData[0].yearGrowthMap.name, //同比增长
            this.listChartData[0].monthGrowthMap.name, //环比增长
            this.listChartData[0].statisticalValueMap.name, //统计值
          ],
        },
        xAxis: [
          {
            type: "category",
            nameLocation: "middle",
            // 日期维度
            data: this.listChartData[0].timeGroupMap,
            axisPointer: {
              type: "shadow",
            },
            axisTick: {
              show: false, //去除X轴上的竖线
            },
          },
        ],
        yAxis: [
          {
            // 统计指标
            type: "value",
            name: this.listChartData[0].statisticalValueMap.name,
            axisLabel: {
              formatter: (value) => that.pushCompany(value),
            },
            min: 0,
          },
          {
            type: "value",
            name: grow,
            axisLabel: {
              formatter: "{value} %",
            },
          },
        ],
        series: [
          // 统计指标
          {
            name: this.listChartData[0].statisticalValueMap.name,
            type: "bar",
            data: this.listChartData[0].statisticalValueMap.data,
          },
          // 同比增长
          {
            name: this.listChartData[0].yearGrowthMap.name,
            type: "line",
            yAxisIndex: 1,
            data: dataYear,
          },
          // 环比增长
          {
            name: this.listChartData[0].monthGrowthMap.name,
            type: "line",
            yAxisIndex: 1,
            data: dataMonth,
          },
        ],
      };
      this.setBarChartsYxias(
        this.options,
        this.listChartData[0].timeGroupMap,
        "x"
      );
    },

 }};
</script>

<style lang="scss" scoped>
.div_charts {
  width: 100%;
  height: 100%;
  ::v-deep .highcharts-container {
    svg {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
</style>
